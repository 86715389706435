import axios from "../axios"
import { useSelector, useDispatch } from "react-redux"
import { authSelector, updateToken } from "../features/authSlice"

const useRefreshToken = () => {
    const auth = useSelector(authSelector);
    const dispacth = useDispatch();

    const refresh = async () => {
        try {
            const response = await axios.get(`/api/users/token`, {
                withCredentials: true,
                crossDomain: true
            })
            dispacth(updateToken(response.data.accessToken))
            return response.data.accessToken
        } catch (error) {
            dispacth(updateToken(''))
        }

    }
    return refresh
}

export default useRefreshToken