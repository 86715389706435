import {
  ViewGridIcon,
  UserGroupIcon,
  CreditCardIcon,
  SwitchHorizontalIcon,
  ViewBoardsIcon,
  ScaleIcon,
  ArrowNarrowLeftIcon,
  PresentationChartLineIcon,
} from "@heroicons/react/solid";

export const menusAdmin = (t) => {
  return [
    { name: t("label.dashboard"), icon: <ViewGridIcon className="h-5 w-5 text-slate-500" />, path: "/admin/dashboard" },
    { name: t("label.client"), icon: <UserGroupIcon className="h-5 w-5 text-slate-500" />, path: "/admin/users" },
    { name: t("label.payment"), icon: <CreditCardIcon className="h-5 w-5 text-slate-500" />, path: "/admin/payments" },
    {
      name: t("label.transaction"),
      icon: <SwitchHorizontalIcon className="h-5 w-5 text-slate-500" />,
      path: "/admin/transactions",
    },
    { name: t("label.onBoarding"), icon: <ViewBoardsIcon className="h-5 w-5 text-slate-500" />, path: "/onboarding" },
  ];
};

export const menusSupport = (t) => {
  return [
    { name: t("label.client"), icon: <ViewGridIcon className="h-5 w-5 text-slate-500" />, path: "/support/dashboard" },
    { name: t("label.lbUsers"), icon: <UserGroupIcon className="h-5 w-5 text-slate-500" />, path: "/support/lb" },
  ];
};

// menu for clients
export const menusClient = (t, id, role) => {
  switch (true) {
    case role.length == 1 && (role.includes("1") || role.includes("0")):
      return [
        {
          name: "Page Admin",
          icon: <ArrowNarrowLeftIcon className="h-5 w-5 text-blue-500" />,
          path: `/hello`,
        },
        {
          name: t("label.rebalancing"),
          icon: <ScaleIcon className="h-5 w-5 text-slate-500" />,
          path: id ? `/rebalancing/${id}` : `/rebalancing`,
        },
        {
          name: "Evolution",
          icon: <PresentationChartLineIcon className="h-5 w-5 text-slate-500" />,
          path: id ? `/evolution/${id}` : `/evolution`,
        },
      ];

    default:
      return [
        {
          name: t("label.rebalancing"),
          icon: <ScaleIcon className="h-5 w-5 text-slate-500" />,
          path: id ? `/rebalancing/${id}` : `/rebalancing`,
        },
        {
          name: "Evolution",
          icon: <PresentationChartLineIcon className="h-5 w-5 text-slate-500" />,
          path: id ? `/evolution/${id}` : `/evolution`,
        },
      ];
  }
};

//menu parrain only

// menu for clients
export const menusParrain = (t, id) => {
  return [
  ];
};
