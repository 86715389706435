import React, { useState } from "react";
import InputFile from "../controls/InputFile";
import axios from "../../axios";
import { useFOrm, Form } from "../useFOrm";
import Controls from "../controls/Controls";
import * as documentService from "../../utils/documentService";
import { toast } from "react-toastify";
import { updateDoc } from "../../features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import DialogBox from "../DialogBox";
import { authSelector } from '../../features/authSlice';
const sourceOfIncomeItems = [
  { id: "retraite", title: "Retraité" },
  { id: "epargne", title: "Epargne" },
  { id: "employe", title: "Employé" },
  { id: "heritage", title: "Héritage" },
  { id: "autre", title: "Autre" },
];
const initialFvalues = {
  type_id: "",
  doc_id: "",
  type_address: "",
  doc_address: "",
  source_income: "",
  otherSourceOfIncome: "",
  doc_contract: "",
  googleDriveLink: "",
};

function DocumentsFormSimple(props) {
  const { data, t, uid } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState("");
  const dispatch = useDispatch();
  const auth = useSelector(authSelector);
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    //if ('type_id' in fieldValues)
    //   temp.type_id = fieldValues.type_id ? "" : t("error.required")
    //if ('doc_id' in fieldValues)
    //    temp.doc_id = fieldValues.doc_id ? "" : t("error.required")
    //if ('type_address' in fieldValues)
    //   temp.type_address = fieldValues.type_address ? "" : t("error.required")
    //if ('doc_address' in fieldValues)
    // temp.doc_address = fieldValues.doc_address ? "" : t("error.required")
    if ("source_income" in fieldValues) temp.source_income = fieldValues.source_income ? "" : t("error.required");
    if ("otherSourceOfIncome" in fieldValues) {
      /*temp.otherSourceOfIncome = (fieldValues.source_income == "autre" && fieldValues.otherSourceOfIncome != "") ? "" :
                (fieldValues.source_income == "autre" && fieldValues.otherSourceOfIncome == "") ? t("error.required") :
                    (fieldValues.source_income != "autre") ? "" : t("error.required")*/
      temp.otherSourceOfIncome =
        fieldValues.source_income === "autre" &&
          (fieldValues.otherSourceOfIncome === null || fieldValues.otherSourceOfIncome === "")
          ? t("error.required")
          : "";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "" || x === {});
    }
  };

  initialFvalues.type_id = data?.files?.identity?.type;
  initialFvalues.type_address = data?.files?.address?.type;
  initialFvalues.source_income = data?.source_income;
  initialFvalues.otherSourceOfIncome = data?.otherSourceOfIncome;
  initialFvalues.googleDriveLink = data?.googleDriveLink;

  const {
    values,
    //setValues,
    errors,
    setErrors,
    //resetForm,
    handleInputChange,
  } = useFOrm(initialFvalues, true, validate);

  const formSource = [
    {
      type: "radio",
      name: "source_income",
      label: t("label.source_income"),
      placeholder: t("label.source_income"),
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const uploadImg = async () => {
    if (validate()) {
      try {
        /*const formData = new FormData();
                formData.append('file', values?.doc_id || "");
                formData.append('fileName', values?.doc_id?.name || "");

                const formData2 = new FormData();
                formData2.append('file', values?.doc_address || "");
                formData2.append('fileName', values?.doc_address?.name || "");

                const formData3 = new FormData();
                formData3.append('file', values?.doc_contract || "");
                formData3.append('fileName', values?.doc_contract?.name || "");


                const config = {
                    headers: {
                        'content-type': 'multipart/form-data',
                    },
                };

                let response, response2, response3;
                if (values?.doc_id !== "") {
                    response = await axios.post('/uploadfile', formData, config);
                }

                if (values?.doc_address !== "") {
                    response2 = await axios.post('/uploadfile', formData2, config);
                }

                if (values?.doc_contract !== "") {
                    response3 = await axios.post('/uploadfile', formData3, config);
                }


                const docToUpdate = {
                    uid: uid,
                    files: {
                        identity: {
                            filename: response?.data[0] ? response?.data[0].filename
                                : data?.files.identity.filename !== "" ? data.files.identity.filename : "",
                            type: values.type_id,
                        },
                        address: {
                            filename: response2?.data[0] ? response2?.data[0].filename
                                : data?.files.address.filename !== "" ? data.files.address.filename : "",
                            type: values.type_address,
                        },
                        contract: {
                            filename: response3?.data[0] ? response3?.data[0].filename
                                : data?.files.contract.filename !== "" ? data.files.contract.filename : "",
                            type: 'docuSign',
                        }
                    },
                    source_income: values.source_income,
                    otherSourceOfIncome: values.otherSourceOfIncome,
                    googleDriveLink: values.googleDriveLink
                }*/
        const docToUpdate = {
          uid: uid,
          files: {
            identity: {
              filename: "",
              type: values.type_id,
            },
            address: {
              filename: "",
              type: values.type_address,
            },
            contract: {
              filename: "",
              type: "docuSign",
            },
          },
          source_income: values.source_income,
          otherSourceOfIncome: values.otherSourceOfIncome,
          googleDriveLink: values.googleDriveLink,
        };

        //find row in doc tables
        const docResult = await axios.get(`/api/documents/${uid}`);

        if (docResult.data) {
          //update
          await axios.put(`/api/documents/${uid}`, docToUpdate);
        } else {
          //create
          await axios.post(`/api/documents/create`, docToUpdate);
        }

        dispatch(updateDoc(docToUpdate));
        toast.success("Doc updated", uid);
      } catch (error) {
        toast.error(error.response?.data.msg);
      }
    }
  };

  const handleClick = (type) => {
    if (!data) return setImage(null);
    setImage(data?.files[type]?.filename);
    setIsOpen(true);
  };

  return (
    <>
      <DialogBox isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="flex justify-center max-h-[500px]">
          {image ? (
            <img className="object-contain max-h-[500px]" src={`http://localhost:5000/static/${image}`} alt="Preuve" />
          ) : (
            <div>no Image</div>
          )}
        </div>
      </DialogBox>

      <div className="flex justify-center p-4 space-y-4">
        <Form encType="multipart/form-data" onSubmit={handleSubmit} noValidate className="flex-none max-w-md space-y-4">

          {formSource.map((item, i) => {
            return (
              <>
                <Controls.RadioGroup
                  items={sourceOfIncomeItems}
                  key={item.label}
                  disabled={auth.id == 1000 ? true : false}
                  label={item.label}
                  value={values[item.name] || ""}
                  onChange={handleInputChange}
                  name={item.name}
                  error={errors[item.name]}
                />
              </>
            );
          })}

          <Controls.Input
            fullWidth={true}
            readOnly={auth.id == 1000 ? true : false}
            label="Autre source de revenu"
            value={values.otherSourceOfIncome || ""}
            onChange={handleInputChange}
            name="otherSourceOfIncome"
            error={errors.otherSourceOfIncome}
          />

          <div className="font-semibold text-sm">Lien GOOGLE DRIVE</div>

          <Controls.Input
            fullWidth={true}
            readOnly={auth.id == 1000 ? true : false}
            label="Lien Google drive"
            value={values.googleDriveLink || ""}
            onChange={handleInputChange}
            name="googleDriveLink"
            error={errors.googleDriveLink}
          />

          <div className={`flex justify-end ${auth.id == 1000 ? 'hidden' : 'block'}`}>
            <Controls.Button text="Sauvegarder" xsFont onClick={uploadImg} />
          </div>
        </Form>
      </div>
    </>
  );
}

export default DocumentsFormSimple;
