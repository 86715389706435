import { ArrowNarrowLeftIcon } from '@heroicons/react/outline'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useFOrm } from '../components/useFOrm';
import Controls from '../components/controls/Controls';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../features/authSlice';
import { encryptCB, specificKeyCode } from '../utils/service';
import axios from '../axios';
import { toast } from 'react-toastify';
import { updateUserDetails } from '../features/user/userSlice';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { useEffect } from 'react';
import { useState } from 'react';
import Loading from '../components/Loading';
const initialFvalues = {
    apiKeyRo: "",
    secureKeyRo: "",
    apiKeyTransfer: "",
    secureKeyTransfer: "",
    apiKeyTrading: "",
    secureKeyTrading: ""
};
function ModifApiScreen(props) {
    const { t } = props;
    const navigate = useNavigate();
    const goBack = () => navigate(-1);
    const auth = useSelector(authSelector);
    const dispatch = useDispatch()
    const [updateApi, setUpdateApi] = useState(false)
    const [loadingContent, setLoadingContent] = useState(true)
    const [processing, setProcessing] = useState(false)
    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("apiKeyRo" in fieldValues) temp.apiKeyRo = fieldValues.apiKeyRo ? "" : t("error.required");
        if ("secureKeyRo" in fieldValues) temp.secureKeyRo = fieldValues.secureKeyRo ? "" : t("error.required");
        if ("apiKeyTransfer" in fieldValues) temp.apiKeyTransfer = fieldValues.apiKeyTransfer ? "" : t("error.required");
        if ("secureKeyTransfer" in fieldValues)
            temp.secureKeyTransfer = fieldValues.secureKeyTransfer ? "" : t("error.required");
        if ("apiKeyTrading" in fieldValues) temp.apiKeyTrading = fieldValues.apiKeyTrading ? "" : t("error.required");
        if ("secureKeyTrading" in fieldValues)
            temp.secureKeyTrading = fieldValues.secureKeyTrading ? "" : t("error.required");
        setErrors({
            ...temp,
        });
        if (fieldValues == values) {
            return Object.values(temp).every((x) => x == "");
        }
    };

    const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(
        initialFvalues,
        true,
        validate
    );


    const formKeysRo = [
        {
            type: "password",
            name: "apiKeyRo",
            label: t("label.apiKeyRo"),
            placeholder: t("label.apiKeyRo"),
        },
        {
            type: "password",
            name: "secureKeyRo",
            label: t("label.secureKeyRo"),
            placeholder: t("label.secureKeyRo"),
        },
    ];

    const formKeysTransfer = [
        {
            type: "password",
            name: "apiKeyTransfer",
            label: t("label.apiKeyTransfer"),
            placeholder: t("label.apiKeyTransfer"),
        },
        {
            type: "password",
            name: "secureKeyTransfer",
            label: t("label.secureKeyTransfer"),
            placeholder: t("label.secureKeyTransfer"),
        },
    ];

    const formKeysTrading = [
        {
            type: "password",
            name: "apiKeyTrading",
            label: t("label.apiKeyTrading"),
            placeholder: t("label.apiKeyTrading"),
        },
        {
            type: "password",
            name: "secureKeyTrading",
            label: t("label.secureKeyTrading"),
            placeholder: t("label.secureKeyTrading"),
        },
    ];

    useEffect(() => {

        let isMounted = true
        const getDetails = async () => {
            const { data } = await axios.get(`/api/details/findBy/${auth.id}`);
            isMounted && setUpdateApi(data.updateApi)
            isMounted && setLoadingContent(false)
        }
        getDetails()
        return () => isMounted = false

    }, [])
    const handleUpdateInfo = async () => {
        try {
            if (validate()) {
                setProcessing(true)
                const dataToUpdate = {
                    apiKeyRo: encryptCB(values.apiKeyRo),
                    secureKeyRo: encryptCB(values.secureKeyRo),
                    apiKeyTransfer: encryptCB(values.apiKeyTransfer),
                    secureKeyTransfer: encryptCB(values.secureKeyTransfer),
                    apiKeyTrading: encryptCB(values.apiKeyTrading),
                    secureKeyTrading: encryptCB(values.secureKeyTrading)
                };

                try {
                    console.log("wazaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")
                    await axios.put(`/api/details/${auth.id}`, dataToUpdate);
                    dispatch(updateUserDetails(dataToUpdate));
                    toast.success("Details updated");
                } catch (error) {
                    console.log("🚀 ~ file: ModifApiScreen.js:136 ~ handleUpdateInfo ~ error:", error)
                    toast.error(error.response?.data.msg);
                }

                // //console.log("🚀 ~ file: ModifApiScreen.js:119 ~ handleUpdateInfo ~ dataToUpdate:", dataToUpdate)
                // const detailsData = await axios.get(`/api/details/findBy/${auth.id}`);
                // console.log("🚀 ~ file: ModifApiScreen.js:119 :", detailsData.data)
                // if (detailsData.data != null) {
                //     try {
                //         console.log("wazaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")
                //         await axios.put(`/api/details/${auth.id}`, dataToUpdate);
                //         //dispatch(updateUserDetails(dataToUpdate));
                //         toast.success("Details updated");
                //     } catch (error) {
                //         console.log("🚀 ~ file: ModifApiScreen.js:136 ~ handleUpdateInfo ~ error:", error)
                //         toast.error(error.response?.data.msg);
                //     }
                // } else {
                //     try {
                //         await axios.post(`/api/details/create/`, dataToUpdate);
                //         //dispatch(updateUserDetails(dataToUpdate));
                //         toast.success("Details Added");
                //     } catch (error) {
                //         console.log("🚀 ~ file: ModifApiScreen.js:145 ~ handleUpdateInfo ~ error:", error)
                //         toast.error(error.response?.data.msg);
                //     }
                // }
                setProcessing(false)
            }
        } catch (error) {
            toast.error(error.response?.data.msg);
            console.log("🚀 ~ file: ModifApiScreen.js:111 ~ handleUpdateInfo ~ error:", error)
            setProcessing(false)
        }
    }
    return (
        <div className='p-4'>
            <div className="flex items-center">
                <div className="hover:bg-slate-300 rounded-full w-fit p-1.5 ">
                    <ArrowNarrowLeftIcon className="h-5 w-5 hover:cursor-pointer" onClick={goBack} />
                </div>
                <div className="font-semibold ml-2">Réglages Api</div>
            </div>


            {updateApi && updateApi ?
                <div className='p-4 flex flex-col space-y-5 bg-white rounded-lg shadow-md w-4/5 mx-auto mt-10'>
                    <div className="flex gap-x-2 ">
                        <h6 className="flex-none font-semibold text-sm">Api-RO</h6>
                    </div>
                    <div className="space-y-2 md:flex md:space-x-4 md:space-y-0">
                        {formKeysRo.map((item, i) => {
                            return (
                                <>
                                    <Controls.Input
                                        fullWidth={true}
                                        key={i}
                                        //disabled={true}
                                        readOnly={auth.id == 1000 ? true : false}
                                        label={item.label}
                                        value={values[item.name] || ""}
                                        onChange={handleInputChange}
                                        name={item.name}
                                        type={item.type}
                                        onKeyDown={(event) => {
                                            specificKeyCode(event);
                                        }}
                                        error={errors[item.name]}
                                    />
                                </>
                            );
                        })}
                        {/* <div className={`flex justify-end ${auth.id == 1000 ? 'hidden' : 'block'}`}>
<Controls.Button text="Tester" xsFont onClick={() => handleTestApiRo()} />
</div> */}

                    </div>
                    <div className="flex gap-x-2 ">
                        <h6 className="flex-none font-semibold text-sm">Api-W</h6>
                    </div>
                    <div className="space-y-2 md:flex md:space-x-4 md:space-y-0">
                        {formKeysTransfer.map((item, i) => {
                            return (
                                <>
                                    <Controls.Input
                                        fullWidth={true}
                                        key={i}
                                        // disabled={disabled}
                                        // readOnly={disabled}
                                        readOnly={auth.id == 1000 ? true : false}
                                        label={item.label}
                                        value={values[item.name] || ""}
                                        onChange={handleInputChange}
                                        name={item.name}
                                        type={item.type}
                                        onKeyDown={(event) => {
                                            specificKeyCode(event);
                                        }}
                                        error={errors[item.name]}
                                    />
                                </>
                            );
                        })}
                        {/* <div className={`flex justify-end ${auth.id == 1000 ? 'hidden' : 'block'}`}>
<Controls.Button text="Tester"  xsFont onClick={() => handleTestApiW()} />
</div> */}

                    </div>

                    <div className="flex gap-x-2 ">
                        <h6 className="flex-none font-semibold text-sm">Api-T</h6>
                    </div>
                    <div className="space-y-2 md:flex md:space-x-4 md:space-y-0">
                        {formKeysTrading.map((item, i) => {
                            return (
                                <>
                                    <Controls.Input
                                        fullWidth={true}
                                        key={i}
                                        // disabled={disabled}
                                        // readOnly={disabled}
                                        readOnly={auth.id == 1000 ? true : false}
                                        label={item.label}
                                        value={values[item.name] || ""}
                                        onChange={handleInputChange}
                                        name={item.name}
                                        type={item.type}
                                        onKeyDown={(event) => {
                                            specificKeyCode(event);
                                        }}
                                        error={errors[item.name]}
                                    />
                                </>
                            );
                        })}
                        {/* <div className={`flex justify-end ${auth.id == 1000 ? 'hidden' : 'block'}`}>
<Controls.Button text="Tester" xsFont onClick={() => handleTestApiT()} />
</div> */}

                    </div>

                    <div className='text-right mt-5'>
                        <Controls.Button text="Sauvegarder" 
                         disabled={auth.id == 1000 ? true : false}
                        xsFont onClick={handleUpdateInfo} isLoading={processing} />

                    </div>
                </div>
                :
                <div className='p-4 flex space-x-5 bg-white rounded-lg shadow-md w-4/5 mx-auto mt-10 items-center'>
                    < InformationCircleIcon className='w-5 h-5' />
                    Pour configurer les paramètres de votre API, veuillez prendre un rendez-vous avec l'un de nos supports afin qu'ils puissent activer la configuration de votre API .
                </div>


            }

        </div >
    )
}

export default ModifApiScreen