import React from "react";
import { useSelector } from "react-redux";
import { authSelector } from "../features/authSlice";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";
import List from '../components/Users/List';
function AllUsersScreen(props) {
  const { t } = props;
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <>
      <div className="p-4 flex gap-3 h-[93vh]">
        <div className="flex-1 bg-white dark:bg-slate-800 gap-y-5 rounded-lg p-4 ">
          <div className="flex items-center">
            <div className="hover:bg-slate-300 rounded-full w-fit p-1.5 ">
              <ArrowNarrowLeftIcon className="h-5 w-5 hover:cursor-pointer" onClick={goBack} />
            </div>

            <div className="font-bold text-xl text-slate-700 dark:text-slate-100">{t("label.allUsers")}</div>
          </div>

          <div className="">
            {" "}
            <div className="flex-auto space-y-4 mt-4 p-4  h-[500px] overflow-y-auto  bg-slate-100 dark:bg-slate-800">
              <List t={t} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllUsersScreen;
