import React from 'react'

function InputFile(props) {
    const { name, onChange, error = null, value } = props
    const convertToDefaultEventPara = (name, value) => ({
        target: {
            name,
            value: value
        }
    })
    return (
        <div>
            <label className="block hover:cursor-pointer">
                <span className="sr-only">Choose profile photo</span>
                <input type="file" className="block w-full text-sm text-slate-500
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-secondary-50 file:text-primary
                    hover:file:bg-blue-100 hover:cursor-pointer"
                    name={name}
                    value={value}
                    onChange={e => onChange(convertToDefaultEventPara(name, e.target.files[0]))}
                />
            </label>

            {error && (
                <span className='text-xs text-red-500'>{error}</span>
            )

            }

        </div>
    )
}

export default InputFile