import React, { useState, useEffect } from "react";
import { useFOrm, Form } from "../useFOrm";
import Controls from "../controls/Controls";
import { decryptCB, specificKeyCode, encryptCB } from "../../utils/service";
import axios from "../../axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { updateUserDetails, userDataSelector } from "../../features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import { authSelector } from '../../features/authSlice';
const isSousCompteItems = [
  { id: true, title: "Oui" },
  { id: false, title: "Non" },
];
const initialFvalues = {
  apiKeyRo: "",
  secureKeyRo: "",
  apiKeyTransfer: "",
  secureKeyTransfer: "",
  apiKeyTrading: "",
  secureKeyTrading: "",
  source_income: "",
  otherSourceOfIncome: "",
  isSousCompte: false,
};
function KeyForm(props) {
  const { data, t, uid } = props;
  const dispatch = useDispatch();
  const auth = useSelector(authSelector);
  const [apiExist, setApiExist] = useState();
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("apiKeyRo" in fieldValues) temp.apiKeyRo = fieldValues.apiKeyRo ? "" : t("error.required");
    if ("secureKeyRo" in fieldValues) temp.secureKeyRo = fieldValues.secureKeyRo ? "" : t("error.required");
    if ("apiKeyTransfer" in fieldValues) temp.apiKeyTransfer = fieldValues.apiKeyTransfer ? "" : t("error.required");
    if ("secureKeyTransfer" in fieldValues)
      temp.secureKeyTransfer = fieldValues.secureKeyTransfer ? "" : t("error.required");
    if ("apiKeyTrading" in fieldValues) temp.apiKeyTrading = fieldValues.apiKeyTrading ? "" : t("error.required");
    if ("secureKeyTrading" in fieldValues)
      temp.secureKeyTrading = fieldValues.secureKeyTrading ? "" : t("error.required");
    setErrors({
      ...temp,
    });

    if (fieldValues == values) {
      //values.source_income === "autre" && values.otherSourceOfIncome === "" ? console.log("autre") : console.log("lol")
      //if not null
      return Object.values(temp).every((x) => x == "");

      /*return Object.values(temp).every(([k, v]) => k === "otherSourceOfIncome" || v === ""

            );*/
    }
  };

  const decryptDataAPI = ["apiKeyRo", "apiKeyTransfer", "apiKeyTrading"];
  const decryptData = ["secureKeyRo", "secureKeyTransfer", "secureKeyTrading"];

  for (const key in initialFvalues) {
    //check if exit in array then decrypt it
    if (decryptDataAPI.includes(key)) {
      initialFvalues[key] = decryptCB(data[key]);
    } else {
      initialFvalues[key] = data[key];
    }
  }

  const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(
    initialFvalues,
    true,
    validate
  );

  const formKeysRo = [
    {
      type: "password",
      name: "apiKeyRo",
      label: t("label.apiKeyRo"),
      placeholder: t("label.apiKeyRo"),
      password: true,
    },
    {
      type: "password",
      name: "secureKeyRo",
      label: t("label.secureKeyRo"),
      placeholder: t("label.secureKeyRo"),
      password: true,
    },
  ];

  const formKeysTransfer = [
    {
      type: "password",
      name: "apiKeyTransfer",
      label: t("label.apiKeyTransfer"),
      placeholder: t("label.apiKeyTransfer"),
      password: true,
    },
    {
      type: "password",
      name: "secureKeyTransfer",
      label: t("label.secureKeyTransfer"),
      placeholder: t("label.secureKeyTransfer"),
      password: true,
    },
  ];

  const formKeysTrading = [
    {
      type: "password",
      name: "apiKeyTrading",
      label: t("label.apiKeyTrading"),
      placeholder: t("label.apiKeyTrading"),
      password: true,
    },
    {
      type: "password",
      name: "secureKeyTrading",
      label: t("label.secureKeyTrading"),
      placeholder: t("label.secureKeyTrading"),
    },
  ];

  /*const formBinance = [
        {
            type: "text",
            name: "binance_address",
            label: t('label.binance_address'),
            placeholder: t('label.binance_address'),

        },
        {
            type: "text",
            name: "memo",
            label: t('label.memo'),
            placeholder: t('label.memo'),

        },
        {
            type: "text",
            name: "network",
            label: t('label.network'),
            placeholder: t('label.network'),
        }

    ];*/

  const formSousCompte = [
    {
      type: "radio",
      name: "isSousCompte",
      label: t("label.subAccount"),
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleUpdateInfo = async () => {
    //if (validate()) {
    //console.log("0", values)

    //delete empty data form input
    let obj = Object.fromEntries(Object.entries(values).filter(([_, v]) => v != null && v != ""));

    //console.log("1", obj)
    const dataToUpdate = {
      ...obj,
      uid: uid,
      apiKeyRo: obj.hasOwnProperty("apiKeyRo") ? encryptCB(obj.apiKeyRo) : "",
      secureKeyRo: obj.hasOwnProperty("secureKeyRo") ? encryptCB(obj.secureKeyRo) : "",
      apiKeyTransfer: obj.hasOwnProperty("apiKeyTransfer") ? encryptCB(obj.apiKeyTransfer) : "",
      secureKeyTransfer: obj.hasOwnProperty("secureKeyTransfer") ? encryptCB(obj.secureKeyTransfer) : "",
      apiKeyTrading: obj.hasOwnProperty("apiKeyTrading") ? encryptCB(obj.apiKeyTrading) : "",
      secureKeyTrading: obj.hasOwnProperty("secureKeyTrading") ? encryptCB(obj.secureKeyTrading) : "",
    };

    //values.apiKeyRo = encryptCB(values.apiKeyRo)
    /* values.secureKeyRo = encryptCB(values.secureKeyRo)
         values.apiKeyTransfer = encryptCB(values.apiKeyTransfer)
         values.apiKeyTrading = encryptCB(values.apiKeyTrading)
         values.secureKeyTrading = encryptCB(values.secureKeyTrading)*/

    //console.log("2", dataToUpdate)
    let finalObjtToUpdate = Object.fromEntries(Object.entries(dataToUpdate).filter(([_, v]) => v != null && v != ""));
    //console.log("3", finalObjtToUpdate)
    try {
      // checkifExist in database
      const detailsData = await axios.get(`/api/details/findBy/${uid}`);

      if (detailsData.data) {
        try {
          axios.put(`/api/details/${uid}`, finalObjtToUpdate);
          dispatch(updateUserDetails(finalObjtToUpdate));
          toast.success("Modification sauvegarder");
        } catch (error) {
          toast.error(error.response?.data.msg);
        }
      } else {
        try {
          axios.post(`/api/details/create/`, finalObjtToUpdate);
          dispatch(updateUserDetails(finalObjtToUpdate));
          toast.success("Modification sauvegarder");
        } catch (error) {
          toast.error(error.response?.data.msg);
        }
      }
    } catch (error) {
      toast.error(error.response?.data.msg);
    }
    //}
  };

  const handleUpdateInfoSoucompte = async () => {
    //isSousCompte
    try {
      axios.put(`/api/details/${uid}`, { isSousCompte: values.isSousCompte });
      toast.success("Modification sauvegarder");
    } catch (error) {
      console.log("🚀 ~ file: KeyForm.js:218 ~ handleUpdateInfoSoucompte ~ error:", error)
      toast.success("Une erreur est survenue");
    }
  }

  const handleReset = () => {
    setValues(initialFvalues);
  };
  const [testApiRo, setTestApiRo] = useState(data && data.testApi.ro);
  const [testApiW, setTestApiW] = useState(data && data.testApi.w);
  const [testApiT, setTestApiT] = useState(data && data.testApi.t);
  const [errorApiRo, setErrorApiRo] = useState("");
  const [errorApiW, setErrorApiW] = useState("");
  const [errorApiT, setErrorApiT] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const [activateManageApi, setActivateManageApi] = useState(false);
  const handleTestApiRo = async () => {
    try {
      const response = await axiosPrivate.get(`/api/details/testro/${uid}`);
      console.log("RO", response);
      if (response.status === 200) {
        setTestApiRo(true);
        const dataToUpdate = {
          ...data.testApi,
          ro: true
        };
        await axiosPrivate.put(`/api/details/${uid}`, { testApi: dataToUpdate });
        dispatch(updateUserDetails({ testApi: dataToUpdate }));
        //setErrorApiRo(response.data);
      }
    } catch (error) {
      console.log("RO", error.response.status);
      setErrorApiRo(JSON.stringify(error.response.data));
      setTestApiRo(false);
      const dataToUpdate2 = {
        ...data.testApi,
        ro: false
      };
      await axiosPrivate.put(`/api/details/${uid}`, { testApi: dataToUpdate2 });
      dispatch(updateUserDetails({ testApi: dataToUpdate2 }));
    }
  };
  const handleTestApiW = async () => {
    try {
      var response = await axiosPrivate.get(`/api/details/testw/${uid}`);
      if (response.status === 200) {
        setTestApiW(true);
        // setErrorApiW(JSON.stringify(response.data));
        const dataToUpdate = {
          ...data.testApi,
          w: true,
        };
        await axiosPrivate.put(`/api/details/${uid}`, { testApi: dataToUpdate });
        dispatch(updateUserDetails({ testApi: dataToUpdate }));
      }

      console.log("W", response);
    } catch (error) {
      console.log("W", error.response.status);
      setTestApiW(false);
      setErrorApiW(JSON.stringify(error.response.data));
      const dataToUpdate2 = {
        ...data.testApi,
        w: false
      };
      await axiosPrivate.put(`/api/details/${uid}`, { testApi: dataToUpdate2 });
      dispatch(updateUserDetails({ testApi: dataToUpdate2 }));
    }
  };
  const handleTestApiT = async () => {
    try {
      var response = await axiosPrivate.get(`/api/details/testt/${uid}`);
      if (response.status === 200) {
        setTestApiT(true);
        // setErrorApiT(JSON.stringify(response.data));
        const dataToUpdate = {
          ...data.testApi,
          t: true,
        };
        await axiosPrivate.put(`/api/details/${uid}`, { testApi: dataToUpdate });
        dispatch(updateUserDetails({ testApi: dataToUpdate }));
      }
      console.log("T", response);
    } catch (error) {
      console.log("T", error.response.status);
      setTestApiT(false);
      setErrorApiT(JSON.stringify(error.response.data));
      const dataToUpdate2 = {
        ...data.testApi,
        t: false
      };
      //{"ro":false,"t":false,"w":false}
      await axiosPrivate.put(`/api/details/${uid}`, { testApi: dataToUpdate2 });
      dispatch(updateUserDetails({ testApi: dataToUpdate2 }));
    }
  };

  const toggleButtonApiManage = async (value) => {
    try {
      setActivateManageApi(value)
      dispatch(updateUserDetails({ updateApi: value }))
      await axiosPrivate.put(`/api/details/${uid}`, { updateApi: value });
    } catch (error) {
      console.log("🚀 ~ file: KeyForm.js:312 ~ toggleButtonApiManage ~ error:", error)
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit} noValidate>
        <div className="flex flex-col p-4 space-y-3">
          <div className="flex flex-col  space-y-3">
            <div className="space-y-2 md:flex md:space-x-4 md:space-y-0">
              {formSousCompte.map((item, i) => {
                return (
                  <Controls.RadioGroup
                    disabled={auth.id == 1000 ? true : false}
                    items={isSousCompteItems}
                    key={i}
                    label={item.label}
                    value={values[item.name] || false}
                    onChange={handleInputChange}
                    name={item.name}
                    error={errors[item.name]}
                  />
                );
              })}
            </div>
            <div>

              <Controls.Button text="Sauvegarder" disabled={auth.id == 1000 ? true : false} xsFont onClick={() => handleUpdateInfoSoucompte()} />
            </div>

          </div>

          <hr />

          {auth.role.includes("0") || auth.role.includes("3") ?
            <div>
              {data && data.updateApi ?
                <Controls.Button text="Desactiver le reglage API pour ce client" color={'bg-red-400'} xsFont onClick={() => toggleButtonApiManage(false)} />
                :
                <Controls.Button text="Activer le reglage API pour ce client" color={'bg-green-400'} xsFont onClick={() => toggleButtonApiManage(true)} />

              }

            </div> : null
          }

          <div className="flex flex-col  space-y-3">
            <div className="flex gap-x-2 ">
              <h6 className="flex-none font-semibold text-sm">Api-RO</h6>
              {testApiRo ? (
                <CheckCircleIcon className="h-4 w-4 text-green-500" />
              ) : (
                <XCircleIcon className="h-4 w-4 text-red-500" />
              )}
            </div>

            <div className="space-y-2 md:flex md:space-x-4 md:space-y-0">
              {formKeysRo.map((item, i) => {
                return (
                  <>
                    <Controls.Input
                      fullWidth={true}
                      key={i}
                      //disabled={true}
                      //readOnly={auth.id == 1000 ? true : false}
                      readOnly={true}
                      label={item.label}
                      value={values[item.name] || ""}
                      onChange={handleInputChange}
                      name={item.name}
                      type={item.type}
                      onKeyDown={(event) => {
                        specificKeyCode(event);
                      }}
                      error={errors[item.name]}
                    />
                  </>
                );
              })}
              <div className={`flex justify-end ${auth.id == 1000 ? 'hidden' : 'block'}`}>
                {auth.role.includes("0") || auth.role.includes("3") ?
                  <Controls.Button text="Tester" color={"bg-yellow-400"} xsFont onClick={() => handleTestApiRo()} />
                  : null
                }
              </div>

            </div>
            <div className="text-xs text-red-500">{errorApiRo && errorApiRo}</div>
          </div>

          <div className="flex flex-col  space-y-3">
            <div className="flex gap-x-2 ">
              <h6 className="flex-none font-semibold text-sm">Api-W</h6>
              {testApiW ? (
                <CheckCircleIcon className="h-4 w-4 text-green-500" />
              ) : (
                <XCircleIcon className="h-4 w-4 text-red-500" />
              )}
            </div>

            <div className="space-y-2 md:flex md:space-x-4 md:space-y-0">
              {formKeysTransfer.map((item, i) => {
                return (
                  <>
                    <Controls.Input
                      fullWidth={true}
                      key={i}
                      // disabled={disabled}
                      // readOnly={disabled}
                      //readOnly={auth.id == 1000 ? true : false}
                      readOnly={true}
                      label={item.label}
                      value={values[item.name] || ""}
                      onChange={handleInputChange}
                      name={item.name}
                      type={item.type}
                      onKeyDown={(event) => {
                        specificKeyCode(event);
                      }}
                      error={errors[item.name]}
                    />
                  </>
                );
              })}
              <div className={`flex justify-end ${auth.id == 1000 ? 'hidden' : 'block'}`}>
                {auth.role.includes("0") || auth.role.includes("3") ?
                  <Controls.Button text="Tester" color={"bg-yellow-400"} xsFont onClick={() => handleTestApiW()} />
                  : null
                }
              </div>

            </div>
            <div className="flex gap-x-3">
              {/* <div className="text-xs">
                {" "}
                code: -4028, msg: 'Withdrawal amount must be greater than the transaction fee.'
              </div> */}
              <div className="text-xs text-red-400">{errorApiW && errorApiW}</div>
            </div>
          </div>

          <div className="flex flex-col  space-y-3">
            <div className="flex gap-x-2 ">
              <h6 className="flex-none font-semibold text-sm">Api-T</h6>
              {testApiT ? (
                <CheckCircleIcon className="h-4 w-4 text-green-500" />
              ) : (
                <XCircleIcon className="h-4 w-4 text-red-500" />
              )}
            </div>

            <div className="space-y-2 md:flex md:space-x-4 md:space-y-0">
              {formKeysTrading.map((item, i) => {
                return (
                  <>
                    <Controls.Input
                      fullWidth={true}
                      key={i}
                      // disabled={disabled}
                      // readOnly={disabled}
                      //readOnly={auth.id == 1000 ? true : false}
                      readOnly={true}
                      label={item.label}
                      value={values[item.name] || ""}
                      onChange={handleInputChange}
                      name={item.name}
                      type={item.type}
                      onKeyDown={(event) => {
                        specificKeyCode(event);
                      }}
                      error={errors[item.name]}
                    />
                  </>
                );
              })}
              <div className={`flex justify-end ${auth.id == 1000 ? 'hidden' : 'block'}`}>

                {auth.role.includes("0") || auth.role.includes("3") ?
                  <Controls.Button text="Tester" color={"bg-yellow-400"} xsFont onClick={() => handleTestApiT()} />
                  : null
                }
              </div>

            </div>
            <div className="flex gap-x-3">
              {/* <div className="text-xs"> code: -1013, msg: 'Invalid quantity.' </div> */}
              <div className="text-xs text-red-500"> {errorApiT && errorApiT}</div>
            </div>
          </div>

          {/* <div className='flex flex-col  space-y-3'>
                    <h6 className='flex-none font-semibold text-sm'>Binance </h6>
                    <div className='space-y-2 md:flex md:space-x-4 md:space-y-0'>
                        {formBinance.map((item, i) => {
                            return (
                                <>
                                    < Controls.Input
                                        fullWidth={true}
                                        key={i}
                                        // disabled={disabled}
                                        // readOnly={disabled}
                                        label={item.label}
                                        value={values[item.name] || ''}
                                        onChange={handleInputChange}
                                        name={item.name}
                                        type={item.type}
                                        onKeyDown={(event) => {
                                            specificKeyCode(event)
                                        }}
                                        error={errors[item.name]}
                                    />
                                </>
                            );
                        })}
                    </div>
                </div> */}

          {/* <div className={`flex justify-end ${auth.id == 1000 ? 'hidden' : 'block'}`}>
            {values.apiKeyRo ? (
              <Controls.Button text="Sauvegarder" xsFont onClick={() => handleUpdateInfo()} />
            ) : (
              <Controls.Button text="Sauvegarder" xsFont onClick={() => handleUpdateInfo()} />
            )}
          </div> */}
        </div>
      </Form>
      <hr />

      <div className="p-4">
        <div className="text-xs font-bold bg-blue-300 w-fit px-2 rounded-md"> IP à Whitelister</div>
        <div className="flex flex-col text-xs font-semibold">
          <span>212.227.215.195</span>
          <span>82.165.54.146</span>
          <span>196.46.57.108</span>
          <span>196.46.57.109</span>
        </div>
      </div>
    </>
  );
}

export default KeyForm;
