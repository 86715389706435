import moment from 'moment'
import React, { useEffect, useState } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DialogBox from '../components/DialogBox';
import Button from '../components/controls/Button';

const OneAccordion = (props) => {

    const { data, title } = props
    return (
        <Accordion className='bg-slate-100 dark:bg-slate-500'>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <div className="text-sm grid gap-y-2 font-semibold">
                    {title}
                </div>
            </AccordionSummary>
            <AccordionDetails className="bg-slate-100 dark:bg-slate-800">
                <table className='w-full'>
                    <thead>
                        <tr className='font-semibold text-xs'>
                            <td>#</td>
                            <td className=''>Crypto</td>
                            <td>Répartitions (%)</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.map((c, index) => (
                            <tr className=' border-b-[1px] text-xs hover:bg-slate-400 hover:text-white transition-all duration-150 ease-in-out' key={index}>
                                <td>{index + 1}</td>
                                <td>{c.asset}</td>
                                <td className=''>{c.percentage}</td>
                            </tr>

                        ))}
                    </tbody>
                </table>

            </AccordionDetails>


        </Accordion >

    )
}



const ConstituentScreen = (props) => {
    const { t } = props
    const axiosPrivate = useAxiosPrivate()
    const today = moment().format("DD-MM-YYYY")
    const [top5, setTop5] = useState()
    const [top10, setTop10] = useState()
    const [top30, setTop30] = useState()
    const [isOpen, setIsOpen] = useState(false)
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getTodayConstituent = async (top) => {
            try {
                const { data } = await axiosPrivate.get(`/api/constituents/findBy/${today}/${top}`, {
                    signal: controller.signal,
                });

                switch (true) {
                    case top == 5:
                        isMounted && setTop5(data)
                        break;
                    case top == 10:
                        isMounted &&  setTop10(data)
                        break;
                    case top == 30:
                        isMounted &&  setTop30(data)
                        break;
                    default:
                        break;
                }
            } catch (error) {
                console.log("text", error)
            }
        }
        //getTodayConstituent()

        const array = [5, 10, 30]

        array.forEach(element => {
            getTodayConstituent(element)
        });
        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [])

    console.log(top5, top10, top30)
    return (
        <>
            <Button text="Répartitions" onClick={() => setIsOpen(!isOpen)} small xsFont />

            <DialogBox isOpen={isOpen} setIsOpen={setIsOpen} title={`Répartitions ${today} `}>
                <div>
                    <OneAccordion title={'TOP 5'} data={top5 && JSON.parse(top5?.constituentsData)} />
                    <OneAccordion title={'TOP 10'} data={top10 && JSON.parse(top10?.constituentsData)} />
                    <OneAccordion title={'TOP 30'} data={top30 && JSON.parse(top30?.constituentsData)} />
                </div>
            </DialogBox>
        </>
    )
}

export default ConstituentScreen