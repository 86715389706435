import React ,{useMemo} from 'react'
import 'chartjs-adapter-moment';
import moment from 'moment';
import zoomPlugin from 'chartjs-plugin-zoom';
import abbreviateNumber from '../../utils/numberFormater';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
  );
  export const options = {
    responsive: true,
    maintainAspectRatio: false,
    
   /*  scales: {
    yAxis: {
        type: 'logarithmic',
        ticks: {  
          min: 10, 
          color: 'black',
          beginAtZero:false, 
          sampleSize: 5,  
          callback: function(value) { return abbreviateNumber(value)}
          }
        
      },
      xAxis: {
        type: 'time',
        time: {
          format: "DD/MM/YYYY"
        },
       }
    },*/

    scales:{
      x:{
       grid:{
        display: false
       },
        type: 'time',
        time: {
          unit: 'month'
        },
       /* ticks: {
          autoSkip: false,
          maxRotation: 0,
          major: {
            enabled: true
          }}*/
       
      },
      y:{
        type: 'logarithmic',
        weight:50,
        ticks:{
          min:10,
          stepSize: 25,
          callback: function(value) { return abbreviateNumber(value)} 
        },
        offset: true,
        scaleLabel: {
          display: true,
          labelString: 'Time Frame'
        }
      }
    },


    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
      tooltip: {
        mode: 'index',
        intersect: false,
         callbacks:{
           title: context =>{
             const d = new Date(context[0].parsed.x)
             return moment(d).format("DD-MM-YYYY")
           }
         }
      },
      zoom: {
        pan: {
            enabled: true,
            mode: 'x'
        },
        limits: {
        x:{
          min:'original' ,
          max :'original'
        }
        },
        zoom: {
            pinch: {
                enabled: true       // Enable pinch zooming
            },
            wheel: {
                enabled: true       // Enable wheel zooming
            },
            mode: 'x',
        }
      }
    },
   /* elements:{
      point:{
        radius:3,
        borderWidth:2,
        backgroundColor:'white',
        hoverRadius:4,
        hoverBorderWidth:4
      }
    }*/
  };

 /* const expensiveCalculation2 =  (dataRow , values, data3) => {
    let qtyCbArr =[]
    let qtyCbrecurringArr =[]
    let capitalInitial = values.actifK;
    let recurringCapital = values.recurringK;

    let arrayOfDates =  dataRow && dataRow.map(d=> d.date)
    let arrayOfindex =  dataRow && dataRow.map(d=> d.index)

  
      arrayOfindex.forEach( (el ,i) =>{
        //let calcQty = capitalInitial / Number(formatData.index[0])
        let dateRef = moment(arrayOfDates[i] ,'YYYY-MM-DD').format('YYYY-MM')
        //console.log("dateRef",dateRef )
        let dateAf = data3 && data3.filter(f=>  moment(f.createdAt ,'YYYY-MM-DD').format('YYYY-MM') === dateRef )
        if(dateAf && dateAf.length >0){
          //arrayOfAf.push(dateAf)
          let finalAf = dateAf[dateAf.length - 1]
          //console.log("finalAf",finalAf ,i)
          let finalAfUsdt = JSON.parse(finalAf?.totalAssets).usdt || 0
          let calcQty = Number(finalAfUsdt) / Number(el)
          qtyCbArr.push(Number(calcQty.toFixed(2)))
        }else{
          qtyCbArr.push(null)
        }
      })

      let formatData ={
        labels : arrayOfDates,
        index:  arrayOfindex,
        mm20m: dataRow &&  dataRow.map(d => d.mm20m),
        bbsup: dataRow &&dataRow.map(d => d.bbsup),
        bbinf: dataRow && dataRow.map(d => d.bbinf),
        order : dataRow && dataRow.map(d => d.order),
        coefOrder: dataRow && dataRow.map(d => d.coefOrder),
        qtyCb: data3 && qtyCbArr
    }

  console.log(formatData)
    return formatData
};*/

const expensiveCalculation3 =  (dataRow , values) => {
  let qtyCbArr =[]
  let qtyCbrecurringArr =[]
  let capitalInitial = values.actifK;
  let recurringCapital = values.recurringK;

  let formatData ={
      labels : dataRow && dataRow.map(d=> d.date),
      capital :dataRow && dataRow.map(d => Number(d.capital)),
    }
  return formatData
};

function MyLineCapital(props) {
  moment.locale('fr'); 
    const {data , values , graphType } = props;
    const getdata = useMemo(()=> expensiveCalculation3(data , values),[data , graphType])
    const getGradient = (ctx, chartArea , scales)=>{
      const gradientBg = ctx.createLinearGradient(chartArea.left , 0 ,chartArea.right , 0)
      gradientBg.addColorStop(0,'#d97706' )
      gradientBg.addColorStop(1,'#4f46e5' )

      return gradientBg
    }
     const data2 = {
        labels : getdata?.labels,
        datasets: [
          {
              label: 'Capital',
              data: getdata?.capital,
              borderColor: '#22c55e', 
              backgroundColor: '#a3e635',
              //pointRadius: 1,
            },
      
           
        ]
      };
  return (
     <Line  options={{
      responsive: true,
      maintainAspectRatio: false,
      scales:{
        x:{
         grid:{
          display: false
         },
          type: 'time',
          time: {
            unit: 'month'
          },
          ticks:{
            color:'#64748b'
          },
        },
        y:{
          display: true,
          type: graphType,
           ticks:{
            color:"#64748b"
          }
         
        }
      },
      plugins: {
        legend: {
          position: 'bottom',
        },
        title: {
          display: true,
          text: 'Mon capital',
        },
        tooltip: {
          mode: 'index',
          intersect: false,
           callbacks:{
             title: context =>{
               const d = new Date(context[0].parsed.x)
               return moment(d).format("DD-MM-YYYY")
             }
           }
        },
        zoom: {
          pan: {
              enabled: true,
              mode: 'x'
          },
          limits: {
          x:{
            min:'original' ,
            max :'original'
          }
          },
          zoom: {
              pinch: {
                  enabled: true       // Enable pinch zooming
              },
              wheel: {
                  enabled: true       // Enable wheel zooming
              },
              mode: 'x',
          }
        }
      },
     /* elements:{
        point:{
          radius:3,
          borderWidth:2,
          backgroundColor:'white',
          hoverRadius:4,
          hoverBorderWidth:4
        }
      }*/
    }} data={data2}  />
  
   
  )
}

export default MyLineCapital