import { DownloadIcon } from "@heroicons/react/solid";
import React, { useState, useRef } from "react";
import ReactToPrint from "react-to-print";

function EmailPreview(props) {
  //const { selectedRow } = props;
  const componentRef = useRef();
  let {
    id,
    date,
    isFirst,
    startCapitalPreviousMonth,
    deposit,
    withdrawal,
    endCapital,
    pnl,
    pnlFivePercent,
    listPreviousFivePercent,
    sumPreviousFivePercent,
    transferFees,
    capitalToRebalance,
    totalRebalancedValue,
    transactionFees,
    startCapitalCurrentMonth,
    balance,
    rate,
    zap,
    zapActive,
    users,
  } = props.selectedRow;

  let hasActiveZap = props.hasActiveZap;
  let balanceObj;
  let beforeTable;
  let afterTable;
  let email2;
  let tauxEurUsdt;
  let rateObj;
  let five_percent_last_month_array;
  let zapObject;
  let zapSection;
  let capitalHorsAjout;
  let realEndCapital;
  let zapFees = 0;
  //console.log("test", props.selectedRow.balance)

  const [dataEmail, setDataEmail] = useState(props?.selectedRow);
  if (props.selectedRow) {
    //parsing json object
    balanceObj = JSON.parse(balance);
    rateObj = JSON.parse(rate);
    five_percent_last_month_array = JSON.parse(listPreviousFivePercent);

    //variable
    tauxEurUsdt = Number(rateObj.eur);

    //cast
    startCapitalPreviousMonth = Number(startCapitalPreviousMonth);
    withdrawal = Number(withdrawal);
    deposit = Number(deposit);
    realEndCapital = Number(endCapital);
    endCapital =
      Number(realEndCapital) - (Number(users.details.securedAmount) - Number((zap && zap.amountSecure) || 0));
    pnl = Number(pnl);
    pnlFivePercent = Number(pnlFivePercent);
    sumPreviousFivePercent = Number(sumPreviousFivePercent);
    transferFees = Number(transferFees);
    capitalToRebalance = Number(capitalToRebalance) - Number(users.details.securedAmount);
    totalRebalancedValue = Number(totalRebalancedValue);
    transactionFees = Number(transactionFees);
    startCapitalCurrentMonth = Number(startCapitalCurrentMonth) - Number(users.details.securedAmount);

    capitalHorsAjout = Number(realEndCapital) - Number(deposit);
    zapFees = zap ? zap.fees : 0;

    //zapObject = zap

    zapSection = `
				${
          zap
            ? `<br/>
				- <b>Sécurisation déclenchée ce mois</b> : ${(zap && "OUI") || "NON"} <br/>
				- <b>Type de sécurisation:</b> ${
          zap && zap.type === 1
            ? "RECOLTE"
            : zap.type === 2
            ? "PALIER"
            : zap.type === 3
            ? "DYNAMIQUE"
            : zap.type === 4
            ? " EXPRESS"
            : ""
        } <br/>
				- <b>Montant sécurisé ce mois </b>: ${(zap && Number(zap.amountSecure)) || 0} (≈ ${Number(
                ((Number(zap.amountSecure) || 0) / tauxEurUsdt).toFixed(2)
              )}€)  <br/>
					${
            zap && zap.type === 4
              ? ` - <b>Frais de sécurisation EXPRESS </b>: ${
                  zap && zapFees == 0 ? "OFFERT" : `${zapFees} (≈ ${Number((zapFees / tauxEurUsdt).toFixed(2))}€)`
                }   <br/> `
              : ""
          }
				- <b>Total montant sécurisé (mois en cours) </b>: ${Number(users.details.securedAmount) || 0} USDT (≈ ${Number(
                ((Number(users.details.securedAmount) || 0) / tauxEurUsdt).toFixed(2)
              )}€) <br/> <br/>

				`
            : zapActive
            ? `<br/>
				- <b>Sécurisation déclenchée ce mois</b> : ${(zapActive && "NON") || "NON"} <br/>
				- <b>Type de sécurisation:</b> ${
          zapActive && zapActive.type === 1
            ? "RECOLTE"
            : zapActive.type === 2
            ? "PALIER"
            : zapActive.type === 3
            ? "DYNAMIQUE"
            : zapActive.type === 4
            ? " EXPRESS"
            : ""
        } <br/>
				- <b>Montant sécurisé ce mois </b>: ${(zapActive && Number(zapActive.amountSecure)) || 0} (≈ ${Number(
                ((Number(zapActive.amountSecure) || 0) / tauxEurUsdt).toFixed(2)
              )}€)  <br/>
				- <b>Total montant sécurisé (mois en cours) </b>: ${Number(users.details.securedAmount) || 0} USDT (≈ ${Number(
                ((Number(users.details.securedAmount) || 0) / tauxEurUsdt).toFixed(2)
              )}€) <br/> <br/>

				`
            : ``
        }

		`;

    beforeTable = `
		<br/>
		Le programme vient de procéder avec succès au rééquilibrage de votre portefeuille Binance. <br/>
		<br/>
		Voici les informations liées à ce rééquilibrage : <br/>
		${
      !isFirst
        ? `

		- <b>Capital de début de période (mois précédent)</b> : ${Number(startCapitalPreviousMonth).toFixed(
      2
    )} USDT (≈ ${Number((startCapitalPreviousMonth / tauxEurUsdt).toFixed(2))}€)  <br />
		- <b>Capital de fin de période hors ajout de capital</b> : ${Number(capitalHorsAjout).toFixed(2)} USDT (≈ ${Number(
            (capitalHorsAjout / tauxEurUsdt).toFixed(2)
          )}€)  <br/>
		- <b>Ajout de capital</b> : ${Number(Number(deposit).toFixed(2)) +
      ` USDT (≈ ${Number((deposit / tauxEurUsdt).toFixed(2))}€) <br/>`}
		${
      Number(deposit) == 0
        ? `
		<span style="font-size:10px ">
		Vous pouvez booster les résultats de la stratégie
	 en mettant en place un ajout de capital récurrent.
		 Voici un tuto pour mettre en place ce virement permanent :
		https://www.binance.com/fr/support/faq/24936156c8e440ce852ba9558fd766aa
		Nous vous recommandons de faire un achat d'USDT. Cet ajout de capital sera réintégré automatiquement
		dans le rééquilibrage mensuel.
		 Vous pouvez contacter le support en répondant à ce mail si vous avez une question à ce sujet. </span> <br/><br/>`
        : ``
    }


		${
      Number(withdrawal) != 0
        ? `- <b>Variation du Capital</b> : ${Number(withdrawal).toFixed(2)} USDT (≈ ${Number(
            (withdrawal / tauxEurUsdt).toFixed(2)
          )}€) <br/>
		<span style="font-size:13px;">
		Cette variation peut provenir de diverses opérations réalisées sur votre compte
		(Stacking, Trades en dehors de notre stratégie, retraits, ou autres).<br />
		Si le montant vous semble significatif,
		vous pouvez contacter notre support pour que nous
		identifions ensemble les raisons de cette variation
		</span>
		<br/><br/> `
        : ``
    }

		`
        : ``
    }

		${
      zap
        ? `- <b>Capital de fin de période</b> :${Number(realEndCapital).toFixed(2)} USDT (≈ ${Number(
            (realEndCapital / tauxEurUsdt).toFixed(2)
          )}€)  <br/>
		`
        : ``
    }

		- ${isFirst ? `<b>Capital actif à rééquilibrer</b> : ` : `<b>Capital actif de fin de période</b> :`} ${Number(
      endCapital
    ).toFixed(2)} USDT (≈ ${Number((endCapital / tauxEurUsdt).toFixed(2))}€)  <br/>


		${
      zap
        ? `- <b>Total montant sécurisé (mois dernier)</b> : ${Number(users.details.securedAmount) -
            Number((zap && zap.amountSecure) || 0)} USDT (≈ ${Number(
            ((Number(users.details.securedAmount) - Number((zap && zap.amountSecure) || 0)) / tauxEurUsdt).toFixed(2)
          )}€)  <br/>
		 `
        : ``
    }


		${
      !isFirst
        ? `
		- <b>Gains sur la période</b> : ${
      Number(pnl) > 0
        ? Number(pnl.toFixed(2)) + ` USDT (≈ ${Number((pnl / tauxEurUsdt).toFixed(2))}€)`
        : `marché en baisse`
    } <br/>
		- <b>5% de frais sur gains de la période</b> :
		${
      pnlFivePercent > 0
        ? pnlFivePercent + ` USDT (≈ ${Number((pnlFivePercent / tauxEurUsdt).toFixed(2))}€)`
        : `Il n'y a pas de gain ce mois-ci, il n'y a donc pas de frais !`
    } <br/>


		${
      pnlFivePercent < 12 && pnlFivePercent > 0 && Number(sumPreviousFivePercent) + Number(pnlFivePercent) < 12
        ? `Le gain étant inférieur à 12€, son prélèvement est reporté au prochain mois en gain <br/>`
        : pnlFivePercent > 12 && transferFees == 0
        ? `Un problème est survenu lors du transfert des 5% de frais sur gains de la période, son prélèvement est reporté au prochain mois en gain<br/>`
        : ``
    }
		${
      five_percent_last_month_array.length != 0
        ? `
		   - <b>Report de frais des mois précédents</b> : <br/>
		   <span style="font-size:14px; margin-left: 40px">
			   ${five_percent_last_month_array
           .map(
             (fp, i) =>
               `- Report de frais de ${fp.date} : ${fp.amount} USDT (≈ ${Number(
                 (Number(fp.amount) / tauxEurUsdt).toFixed(2)
               )}€) <br/>`
           )
           .join("")}
		   </span>
		   ${
         Number((Number(sumPreviousFivePercent) + Number(pnlFivePercent)).toFixed(2)) >= 12 &&
         Number(pnlFivePercent).toFixed(2) > 0
           ? `
			   - <b>Total des frais prélevés </b> : ${Number(sumPreviousFivePercent.toFixed(2)) +
           Number(pnlFivePercent.toFixed(2))} USDT (≈ ${Number(
               ((Number(sumPreviousFivePercent) + Number(pnlFivePercent)) / tauxEurUsdt).toFixed(2)
             )}€) <br/>`
           : `- <b>Total des frais à prélever</b> : ${Number(sumPreviousFivePercent.toFixed(2)) +
               Number(pnlFivePercent.toFixed(2))} USDT (≈ ${Number(
               ((sumPreviousFivePercent + pnlFivePercent) / tauxEurUsdt).toFixed(2)
             )}€) <br/>`
       }`
        : ``
    }
		${
      Number((Number(sumPreviousFivePercent) + Number(pnlFivePercent)).toFixed(2)) < 12
        ? ""
        : `- <b>Frais Binance du transfert des gains</b> : ${transferFees} USDT (≈ ${Number(
            (transferFees / tauxEurUsdt).toFixed(2)
          )}€) <br/>`
    }


		${zapSection}


		- <b>Capital actif à rééquilibrer</b> : ${Number(capitalToRebalance).toFixed(2)} USDT (≈ ${Number(
            (capitalToRebalance / tauxEurUsdt).toFixed(2)
          )}€) <br/>
		`
        : ``
    }

		- <b>Montant des transactions nécessaires au rééquilibrage</b> : ${Number(totalRebalancedValue).toFixed(
      2
    )} USDT (≈ ${Number((totalRebalancedValue / tauxEurUsdt).toFixed(2))}€) <br/>
		- <b>Frais Binance sur les transactions</b> : ${Number(transactionFees).toFixed(2)} USDT (≈ ${Number(
      (transactionFees / tauxEurUsdt).toFixed(2)
    )}€) <br/>
		- <b>Capital actif de début de période (mois en cours)</b> : ${Number(startCapitalCurrentMonth).toFixed(
      2
    )} USDT (≈ ${Number((startCapitalCurrentMonth / tauxEurUsdt).toFixed(2))}€) <br />
		<br />

		Voici la nouvelle répartition de votre portefeuille crypto:
		`;

    afterTable = `
		Nous en profitons pour vous rappeler que la stratégie repose sur un mécanisme simple et totalement transparent qui permet de suivre la performance des 30 meilleures capitalisations Crypto sur la base d'un rééquilibrage mensuel. Le logiciel de rééquilibrage ne fait que reproduire ce mécanisme, il n'y a donc aucune intervention humaine qui pourrait altérer les résultats. C'est important car cela induit que la performance dépend uniquement de l'évolution du marché. Cela induit que vous avez fait le pari (comme nous) que le marché des cryptos sera haussier sur le très long terme, ce qui est un prérequis pour le succès de la stratégie. Ne tenez donc pas compte des pics de variations à la hausse comme à la baisse dont vous pourrez entendre parler dans votre entourage ou les médias. Soyez fidèles ! Que la marée soit haute ou basse, restez accroché à votre rocher ! ;-)
		<br/>
		<br/>
		A votre succès.<br/>
		<br/>
		Florent FOUQUE<br/>

		<br/>
		<p style="font-size:12px">
		PS : Ce message est envoyé de façon automatique par le logiciel après le rééquilibrage. Pour toutes questions concernant les informations contenues dans ce mail, vous pouvez contacter le support simplement en répondant à ce message.
		</p>

		`;
    email2 = {
      text: `
	 <!DOCTYPE html>
	 <html lang="en">

	 <head>
		 <meta charset="UTF-8">
		 <meta http-equiv="X-UA-Compatible" content="IE=edge">
		 <meta name="viewport" content="width=device-width, initial-scale=1.0">

		 <style>
			 body {
				 margin: 0;
				 background-color: #cccc;
			 }



			 td {
				 padding: 0;
			 }

			 img {
				 border: 0;
			 }

			 .wrapper {
				 width: 100%;
				 background-color: #cccc;
				 padding-bottom: 40px;
			 }

			 .main {
				 background-color: #fff;
				 margin: 0 auto;
				 width: 100%;
				 max-width: 900px;
				 border-spacing: 0;
				 font-family: sans-serif;
				 color: #4a4a4a;
			 }
			 .image{
				 display:none;
			 }

			 @media screen and (max-width:600px) {}
		 </style>
	 </head>

	 <body>
		 <div class="" width="210mm" >
			 <table class="main" width="100%">
				 <!--  LOGO -->
				 <tr>
					 <td>
						 <table width="100%">
							 <tr>
								 <td style="display:flex; justify-content:center;text-align:center; padding:10px ; background-color:darkblue">
								
								 </td>
							 </tr>
						 </table>
					 </td>
				 </tr>

				 <!--  TEXt -->
				 <tr>
					 <td>
						 <table width="100%">
							 <tr>
								 <td>
									 <p style="font-size:14px; padding:5px 10px 15px; text-align:justify;">
										${beforeTable}
									 </p>
								 </td>
							 </tr>
						 </table>
					 </td>
				 </tr>

				 <!--  list coin -->

				 <tr>
					 <td style="padding: 10px 30px ;">
						 <table width="100%" style=" font-size:12px; text-align:right ;">
							 <thead>
								 <tr style="font-weight:bold;">
									 <td>#</td>
									 <td>Crypto</td>
									 <td>Qté</td>
									 <td>USDT</td>
									 <td>EUR</td>
								 </tr>
							 </thead>

							 <tbody>

								 ${balanceObj
                   .map(
                     (n, index) =>
                       `
									 <tr style="border-bottom: 1px solid #ccc;">
									 <td style="padding:5px 0px">${index + 1}</td>
										 <td style="padding:5px 0px">${n.asset}</td>
										 <td style="padding:5px 0px">${n.free}</td>
										 <td style="padding:5px 0px">${n.value.usdt}</td>
										 <td style="padding:5px 0px">≈ ${Number((n.value.usdt / tauxEurUsdt).toFixed(2))}</td>
									 </tr>
									 `
                   )
                   .join("")}




							 </tbody>

						 </table>
					 </td>
				 </tr>

				 <tr>
					 <td>
						 <table width="100%">
						 <p style="font-size:14px; line-height:23px ; text-align:justify ; padding:5px 10px 15px;">
						 ${afterTable}
						 </p>

						 </table>
					 </td>
				 </tr>

			 </table>
		 </div>

	 </body>

	 </html>
	 `,
    };
  }

  const DisplayedValue = ({ value }) => {
    return ` ${Number(value).toFixed(2)} USDT (≈${Number((value / tauxEurUsdt).toFixed(2))}€ )`;
  };
  return (
    <div className="m-2">
      <div className={`${props.selectedRow ? "flex justify-end" : "hidden"}`}>
        <ReactToPrint
          trigger={() => (
            <button className="flex space-x-1 bg-sky-100 border border-sky-700 rounded p-1 items-center mb-1 ">
              <DownloadIcon className="h-3 w-3 " />
              <div className="font-semibold text-xs">Télécharger</div>
            </button>
          )}
          content={() => componentRef.current}
        />
      </div>

      <div dangerouslySetInnerHTML={{ __html: email2?.text }} ref={componentRef} />
    </div>
  );
}

export default EmailPreview;
