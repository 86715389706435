import { axiosPrivate } from "../axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import { authSelector, authTokenSelector, logout } from "../features/authSlice"
import { useSelector, useDispatch } from "react-redux"
import setAuthToken from "../utils/setAuthToken";
import * as services from "../utils/service.js"
const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const auth = useSelector(authSelector);
    const dispacth = useDispatch()

    useEffect(() => {

        async function fetchData() {
            // You can await here
            console.log(localStorage.getItem("token"))
            const here = services.decryptCB(localStorage.getItem("token"))
            await setAuthToken(here)
            // ...
        }
        fetchData();

    }, [auth])
    /* useEffect(() => {
         const requestIntercept = axiosPrivate.interceptors.request.use(
             config => {
                 if (!config.headers['Authorization']) {
                     config.headers['Authorization'] = `Bearer ${auth?.token}`
                 }
                 return config;
             }, (error) => Promise.reject(error)
         )
         const responseIntercept = axiosPrivate.interceptors.response.use(
             response => response,
             async (error) => {
                 const prevRequest = error?.config;
                 if (error?.response?.status === 403 && !prevRequest?.sent) {
                     prevRequest.sent = true;
                     const newAcccessToken = await refresh();
                     //console.log("NEW ACCESS", newAcccessToken)
                     prevRequest.headers['Authorization'] = `Bearer ${newAcccessToken}`
                     return axiosPrivate(prevRequest)
                 }
                 if (error?.response?.status === 401) {
                     return dispacth(logout())
                 }
 
                 return Promise.reject(error)
             }
         )
 
         return () => {
             axiosPrivate.interceptors.request.eject(requestIntercept)
             axiosPrivate.interceptors.response.eject(responseIntercept)
         }
     }, [auth, refresh])*/
    return axiosPrivate;
}

export default useAxiosPrivate;