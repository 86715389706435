import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useDarkMode from "../../hooks/useDarkMode"
import useOpen from "../../hooks/useOpen"
import { MoonIcon, SunIcon, LogoutIcon, ArrowNarrowLeftIcon } from '@heroicons/react/outline'
import { sideBarSelector } from "../../features/sideBarSlice"

import { updateShow } from "../../features/sideBarSlice"

function Sidebar(props) {

    const { menus } = props
    const navigate = useNavigate();
    const location = useLocation();
    //const values = useSelector(sideBarSelector)
    const value = useSelector(sideBarSelector)

    const dispacth = useDispatch();


    return (

        <div className='bg-slate-100 dark:bg-slate-800
         md:flex flex-col justify-between items-center 
          w-20 md:w-52 
         border-r-2 border-r-slate-300 
          dark:border-r-slate-600 
          min-h-92
        hidden md:fixed h-screen top-12
          '>
            {/* // <div className={`
        // bg-slate-100 dark:bg-slate-800
        // w-52
        // z-10
        //  flex flex-col justify-between items-center 
        //  border-r-2 border-r-slate-300 
        //  dark:border-r-slate-600 
        //  min-h-92
        //  fixed top-0 h-screen md:h-auto ${value === 'hide' ? '-translate-x-full md:translate-x-0' : 'translate-x-0'} md:relative md:top-0
        //  transition-translate duration-300 
        //  `} > */}
            <div className='flex flex-col w-full flex-grow"'>
                {/* <div className='h-12 md:hidden flex justify-end items-center mx-3'>
                    <ViewListIcon className='h-5 w-5' onClick={() => { dispacth(updateShow('hide')) }} />
                </div> */}


                {/* <div className='rounded-full bg-borderGrey w-8 h-8 mx-auto '></div> */}
                {/* <a onClick={() => navigate('/admin/dashboard/')}>
                    <div
                        className={`bg-orange-400 border-l-4 w-full flex space-x-2 py-2 pl-3 hover:bg-slate-200 cursor-pointer text-sm items-center text-black dark:text-white`}
                    >
                        <span>
                            <ArrowNarrowLeftIcon className='text-red-500 h-5 w-5' />
                        </span>
                        <span className=''>
                            Revenir dans Admin
                        </span>

                    </div>
                </a> */}
                {/* dispacth(updateShow('hide')) */}
                {menus?.map(menu => (
                    <a key={menu.name} onClick={() => { navigate(menu.path); }}>
                        <div
                            className={`${location.pathname == menu.path ? 'bg-slate-200 dark:bg-slate-300  border-l-indigo-500 font-medium' : 'border-l-slate-100'} border-l-4 w-full flex space-x-2 py-2 pl-3 hover:bg-slate-200 cursor-pointer text-sm items-center text-black dark:text-white`}
                        >
                            <span>
                                {menu.icon}
                            </span>
                            <span className='text-xs'>
                                {menu.name}
                            </span>

                        </div>
                    </a>
                ))}


            </div>
            {/* <div className='flex-1 bg-red-100'>efwf

            </div> */}
            {/* notification and user avatar 
            <div className='flex flex-col items-center justify-center space-y-4'>

                <div className='rounded-full w-8 h-8 p-1 hover:cursor-pointer ' onClick={() => setColorTheme(colorTheme)}>
                    {colorTheme === 'dark' ? (
                        <MoonIcon className='h-6 w-6 text-blue-600' />
                    ) : (
                        <SunIcon className='h-6 w-6 text-blue-600' />
                    )}

                </div>



                <LogoutIcon className='w-6 h-6 dark:text-slate-200' />


                <div className='rounded-full bg-gray-400 w-8 h-8 '>
                    <div className="avatar">

                        <img className='object-cover rounded-full w-8 h-8' src="https://api.lorem.space/image/face?hash=28212" />

                    </div>
                </div>
            </div>
*/}
        </div>





    )
}

export default Sidebar