import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
// import { createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "react-toastify/dist/ReactToastify.css";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import LoginScreen from "./screens/LoginScreen";
import UnauthorizedScreen from "./screens/UnauthorizedScreen";
import OneUser from "./components/support/OneUser";

// Clients
import ClientRebalancingScreen from "./screens/ClientRebalancingScreen";

//Forgot password
import PageNotFound from "./components/pageNotFound";
import Layout from "./components/Layout";

import CpScreen from "./screens/CpScreen";
import SimulatorScreen from "./screens/SimulatorScreen";
import MaintenanceScreen from "./screens/MaintenanceScreen";
import SettingScreen from "./screens/SettingScreen";
import AuthScreen from "./screens/AuthScreen";
import AllUsersScreen from "./screens/AllUsersScreen";
import ConstituentScreen from './screens/ConstituentScreen';
import ModifApiScreen from './screens/ModifApiScreen';

const ROLES = {
  SuperAdmin: "0",
  Admin: "1",
  Client: "2",
  Support: "3",
  Partenaire: "4",
  Test: "1234",
};

function App() {
  const { t } = useTranslation();

  const maintenance = false;
  return (
    <>
      {maintenance ? (
        <MaintenanceScreen />
      ) : (
        <>
          <BrowserRouter>
            <ToastContainer hideProgressBar={true} />
            <Routes>
              <Route path="login" element={<LoginScreen t={t} />} />
              <Route exact path="/" element={<LoginScreen t={t} />} />
              <Route path="/" element={<Layout t={t} />}>
                {/* Public Route */}
                <Route path="unauthorized" element={<UnauthorizedScreen />} />

                <Route element={<PersistLogin />}>
                  {/* Super admin Route */}
                  <Route element={<RequireAuth allowedRole={[ROLES.SuperAdmin, ROLES.Admin, ROLES.Support]} />}>
                    <Route path="/hello" element={<CpScreen t={t} />} />
                    <Route path="/user" element={<AllUsersScreen t={t} />} />
                    {/* <Route path="/constituent" element={<ConstituentScreen t={t} />} /> */}
                  </Route>

                  <Route element={<RequireAuth allowedRole={[ROLES.Support, ROLES.SuperAdmin, ROLES.Admin]} />}>
                    <Route path="/support/user/:id" element={<OneUser t={t} />} />
                    <Route path="/rebalancing/:id" element={<ClientRebalancingScreen t={t} />} />
                    <Route path="/evolution/:id" element={<SimulatorScreen t={t} />} />
                  </Route>

                  <Route element={<RequireAuth allowedRole={[ROLES.Client, ROLES.SuperAdmin, ROLES.Support]} />}>
                    <Route path="/settings" element={<SettingScreen t={t} />} />
                    <Route path="/reauth" element={<AuthScreen t={t} />} />
                  </Route>

                  {/* Client Route */}
                  <Route element={<RequireAuth allowedRole={[ROLES.Client]} />}>
                    <Route path="/rebalancing" element={<ClientRebalancingScreen t={t} />} />
                    <Route path="/evolution" element={<SimulatorScreen t={t} />} />
                    <Route path="/keys" element={<ModifApiScreen t={t} />} />
                  </Route>
                </Route>

                {/* not found */}
                <Route path="*" element={<PageNotFound />} />
              </Route>
            </Routes>
          </BrowserRouter>
          <CssBaseline />
        </>
      )}
    </>
  );
}

export default App;
