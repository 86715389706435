import React from 'react'
import { useNavigate } from "react-router-dom"
import { ReactComponent as NotFound } from '../../asset/notFound.svg';
import Controls from '../controls/Controls';

function PageNotFound() {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  return (

    <div className='flex flex-col justify-center items-center space-y-4'>
      <NotFound />
      <Controls.Button text={`Revenir`}
        onClick={() => goBack()}
      />
    </div>
  )
}

export default PageNotFound