import React, { useState, useRef } from "react";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LoginForm from "./LoginForm";
import { useNavigate, Link } from "react-router-dom";
import { login } from "../../features/user/userSlice";
import Button from "../controls/Button";
import { ReactComponent as CBLOGO } from "../../asset/logo.svg";

import { useSelector, useDispatch } from "react-redux";

function Login(props) {
	const { t } = props;
	const dispatch = useDispatch();
	return (
		<div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
			<div className="max-w-md w-full space-y-3">
				<div>
					<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-slate-100">
						Connectez-vous à votre compte
					</h2>
				</div>
				<LoginForm t={t} />
			</div>
		</div>
	);
}

export default Login;
