import React from 'react'
import { useFOrm, Form } from '../useFOrm';
import Controls from '../controls/Controls';
import axios from '../../axios';
import { toast } from 'react-toastify';
import { updateUser } from '../../features/user/userSlice'
import { useDispatch, useSelector } from "react-redux"
import { authSelector } from "../../features/authSlice"
const initialFvalues = {
    firstName: "",
    lastName: "",
    email: "",
    tel: "",
    code: ""

}

function InfoForm(props) {
    const { data, t, uid } = props
    const dispatch = useDispatch()
    const auth = useSelector(authSelector);
    const validate = (fieldValues = values) => {
        let temp = { ...errors }

        if ('firstName' in fieldValues)
            temp.firstName = fieldValues.firstName ? "" : t("error.required")
        if ('lastName' in fieldValues)
            temp.lastName = fieldValues.lastName ? "" : t("error.required")
        if ('email' in fieldValues)
            temp.email = fieldValues.email ? "" : t("error.required")
        //temp.email = fieldValues.email == "" ? t("error.required") : (/$^|.+@.+..+/).test(fieldValues.email) ? "" : t("error.invalidEmail")
        if ('tel' in fieldValues)
            temp.tel = fieldValues.tel > 9 ? "" : t("error.mobileLength")
        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "");
    }

    initialFvalues.email = data.email
    initialFvalues.firstName = data.firstName
    initialFvalues.lastName = data.lastName
    initialFvalues.tel = data.tel

    const {
        values,
        setValues,
        errors,
        setErrors,
        resetForm,
        handleInputChange
    } = useFOrm(initialFvalues, true, validate)



    const formInfo = [
        {
            type: "text",
            name: "lastName",
            label: t('label.lastName'),
            placeholder: t('placeholder.lastName'),
            password: false,
            required: true,
        },
        {
            type: "text",
            name: "firstName",
            label: t('label.firstName'),
            placeholder: t('placeholder.firstName'),
            password: false,
            required: true,
        },
        /* {
             type: "email",
             name: "email",
             label: t('label.email'),
             placeholder: t('placeholder.firstName'),
             password: false,
             required: true,
         },
         {
             type: "tel",
             name: "tel",
             label: t('label.tel'),
             placeholder: t('placeholder.tel'),
             password: false,
             required: true,
         }*/

    ];

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const handleUpdateInfo = async () => {
        if (validate()) {
            //update user data
            try {
                axios.put(`/api/users/${uid}`, values)
                dispatch(updateUser(values))
                toast.success("User updated");
            } catch (error) {
                toast.error(error.response?.data.msg);

            }

        }
    }


    return (
        <div>
            <Form onSubmit={handleSubmit} noValidate>
                <div className='space-y-4 max-w-md my-4'>


                    {formInfo.map((item, i) => {
                        // console.log(data[item.name])
                        return (

                            < Controls.Input
                                fullWidth={true}
                                key={i}
                                // disabled={disabled}
                                readOnly={auth.id == 1000 ? true : false}
                                label={item.label}
                                value={values[item.name] || ''}
                                onChange={handleInputChange}
                                name={item.name}
                                type={item.type}
                                error={errors[item.name]}
                            />

                        );
                    })}
                </div>
                <div className={`flex justify-end ${auth.id == 1000 ? 'hidden' : 'block'}`}>
                    <Controls.Button text="Sauvegarder" xsFont onClick={() => handleUpdateInfo()} />
                </div>
            </Form>
        </div >
    )


}

export default InfoForm