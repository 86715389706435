import React, { useEffect, useState } from "react";
import { MoonIcon, SunIcon, LogoutIcon, LoginIcon, UserCircleIcon, CogIcon, UserGroupIcon } from "@heroicons/react/outline";
import useDarkMode from "../../hooks/useDarkMode";
import useOpen from "../../hooks/useOpen";
import { ReactComponent as CBLOGO } from "../../asset/logo.svg";
import { authSelector, logout } from "../../features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { updateTheme } from "../../features/themeSlice";
import { updateShow } from "../../features/sideBarSlice";
import { useNavigate, Link } from "react-router-dom";
import MyPopover from "../MyPopover";
import Controls from "../controls/Controls";
import { ReactComponent as Ampoule } from "../../asset/ampoule.svg";
function Header(props) {
  const { t } = props;
  const [colorTheme, setColorTheme] = useDarkMode();
  const dispacth = useDispatch();
  const auth = useSelector(authSelector);
  //const [isOpen, setOpen] = useOpen()
  const [isOpen, setIsOpen] = useState();
  const navigate = useNavigate();
  const Hello = () => {
    navigate("/login");
  };
  const goodBye = () => {
    setIsOpen(false);
    dispacth(logout());
    navigate("/login");
  };

  const CustomMenu = ({ icon, title, onClick }) => (
    <div className="flex text-sm items-center p-2 hover:bg-slate-200 hover:cursor-pointer gap-x-2" onClick={onClick}>
      {icon}
      {title}
    </div>
  );

  return (
    <>
      <div
        className={`z-10 shadow-sm flex justify-between items-center p-2  ${
          auth.role.includes("0") ? "bg-gradient-to-r from-blue-900 to-blue-100" : "bg-slate-100"
        } dark:bg-slate-800 sticky top-0 w-full`}
      >
        {/* <CBLOGO /> */}

        <div className="flex gap-x-4 items-center">
          {/* <img
              className="object-contains h-8 rounded-full"
              src="https://source.unsplash.com/radom"
            /> */}

          <div className="flex gap-x-4 items-center">
            {auth.role.includes(process.env.REACT_APP_SUPADMIN) ? (
              <>
                <div className="flex items-center space-x-2 text-xs font-normal ml-10 rounded-md hover:cursor-pointer hover:text-secondary text-slate-300" onClick={() => navigate("/user")}>
                  <UserGroupIcon className="w-4 h-4 dark:text-slate-300 " />
                  <div>Interne</div>
                </div>
              </>
            ) : null}
          </div>
        </div>
        {/* </Link> */}
        <MyPopover isOpen={isOpen} setIsOpen={setIsOpen} onMouseEnter={() => setIsOpen(false)}>
          <div className="bg-slate-100 flex-col shadow-xl border-r-2 border-r-indigo-500 ">
            <div className="flex items-center text-sm  text-slate-800 font-semibold truncate h-10 my-auto px-2">{auth.email}</div>
            {/* {auth.role.includes("2") ? ( */}
            <CustomMenu icon={<CogIcon className="w-5 h-5 dark:text-slate-200 " />} title={"Paramètres"} onClick={() => navigate("/settings")} />
            {/* ) : null} */}

            <CustomMenu icon={<LogoutIcon className="w-5 h-5 dark:text-slate-200 " />} title={"Se déconnecter"} onClick={goodBye} />
          </div>
        </MyPopover>

        {/* <div>
                <ViewListIcon className='h-5 w-5 block md:hidden' onClick={() => { dispacth(updateShow('show')) }} />
            </div> */}
        <div className=" flex justify-end space-x-3 items-center">
          {/* <div className='rounded-full p-1 hover:cursor-pointer ' onClick={() => { setColorTheme(colorTheme); dispacth(updateTheme(colorTheme)) }}>
          {colorTheme === 'dark' ? (
            <MoonIcon className='h-5 w-5 text-indigo-600' />
          ) : (
            <SunIcon className='h-5 w-5 text-indigo-600' />
          )}

        </div> */}

          {auth.email ? (
            <>
              <div className={`text-xs font-semibold text-slate-700`}>{auth.name}</div>
              {/* <div className='cursor-pointer hover:bg-slate-200 rounded-full p-1.5'>
                        <LogoutIcon className='w-5 h-5 dark:text-slate-200 ' onClick={goodBye} />

                    </div> */}

              {/* <div className='rounded-full bg-gray-400 w-8 h-8 '>
                        <div className="avatar">

                            <img className='object-cover rounded-full w-8 h-8' src="https://picsum.photos/200" />


                        </div>

                    </div> */}

              <UserCircleIcon className="w-5 h-5 hover:cursor-pointer" onClick={() => setIsOpen(!isOpen)} onMouseEnter={() => setIsOpen(true)} />
            </>
          ) : (
            <>
              {/* <div className='cursor-pointer hover:bg-slate-200 rounded-full p-1.5'>
                            <LoginIcon className='w-5 h-5 dark:text-slate-200 ' onClick={Hello} />

                        </div> */}
              {/* <Controls.Button text="Login" small xsFont onClick={Hello} /> */}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Header;
