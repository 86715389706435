import { createSlice, current } from "@reduxjs/toolkit";

const initialState = [];

export const ticketSlice = createSlice({
    name: "tickets",
    initialState,
    reducers: {
        setTicketsList: (state, action) => {
            return action.payload;
        },
        updateTicket: (state, action) => {
            return { ...state, ...action.payload };
        },
        updateTicketFollow: (state, action) => {
            const changedIdx = state.findIndex(t => (t.id == action.payload.id))
            // console.log(current(state.t[action.payload.index]))
            console.log(changedIdx)
            return [
                ...state.slice(0, changedIdx),
                {
                    ...state[changedIdx],
                    onClickDate: action.payload?.onClickDate || null,
                    comment: action.payload?.comment || null,
                    late: action.payload?.late || null,
                    enableButtonDate: action.payload?.enableButtonDate || null,
                    by: action.payload?.by
                },
                ...state.slice(changedIdx + 1)]
        },
    },
});

export const { setTicketsList, updateTicket, updateTicketFollow } = ticketSlice.actions;
export const ticketsSelector = (state) => state.tickets;

export default ticketSlice.reducer;