import React, { useState, useEffect } from "react";
import useRefreshToken from "../../hooks/useRefreshToken";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import { DataGrid, frFR, GridToolbar } from "@mui/x-data-grid";
import Controls from "../controls/Controls";

//Slices
import { setallUsers, listUserSelector } from "../../features/listUserSlice";
import { addFilter, filterSelector } from "../../features/filterSlice";
import { useDispatch, useSelector } from "react-redux";
import { XIcon, CheckIcon, CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import moment from "moment";
import Loading from "../Loading";
import { authSelector } from "../../features/authSlice";
//servies

const ComponentChip = ({ params, t }) => {
  if (params.value === t("uStatus.0")) {
    return <span className="rounded-full border-2 border-slate-500 w-fit text-center px-1 text-slate-500 text-xs ">{t("uStatus.0")}</span>;
  } else if (params.value === t("uStatus.1")) {
    return <span className="rounded-full border-2 border-green-500 w-fit text-center px-1 text-green-500 text-xs ">{t("uStatus.1")}</span>;
  } else if (params.value === t("uStatus.2")) {
    return <span className="rounded-full border-2 border-yellow-500 w-fit text-center px-1 text-yellow-500 text-xs ">{t("uStatus.2")}</span>;
  } else if (params.value === t("uStatus.3")) {
    return <span className="rounded-full  border-2 border-orange-500 w-fit text-center px-1 text-orange-500 text-xs ">{t("uStatus.3")}</span>;
  } else if (params.value === t("uStatus.4")) {
    return <span className="rounded-full border-2 border-lime-500 w-fit text-center px-1 text-lime-500 text-xs ">{t("uStatus.4")}</span>;
  } else if (params.value === t("uStatus.5")) {
    return <span className="rounded-full border-2 border-red-500 w-fit text-center px-1 text-red-500 text-xs ">{t("uStatus.5")}</span>;
  } else if (params.value === t("uStatus.6")) {
    return <span className="rounded-full border-2 border-sky-500 w-fit text-center px-1 text-sky-500 text-xs ">{t("uStatus.6")}</span>;
  } else if (params.value === t("uStatus.7")) {
    return <span className="rounded-full border-2 border-amber-500 w-fit text-center px-1 text-amber-500 text-xs ">{t("uStatus.7")}</span>;
  } else if (params.value === t("uStatus.9")) {
    return <span className="rounded-full border-2 border-pink-500 w-fit text-center px-1 text-slate-800 text-xs ">{t("uStatus.9")}</span>;
  } else if (params.value === t("uStatus.10")) {
    return <span className="rounded-full border-2 border-indigo-500 px-1 w-fit text-center text-indigo-500  text-xs ">{t("uStatus.10")}</span>;
  }
};
const ComponentRole = ({ params, t }) => {
  if (params.value === t("uRole.2")) {
    return <span className="rounded-full  text-blue-700 w-fit text-center px-2  text-xs font-semibold">{t("uRole.2")}</span>;
  } else if (params.value === t("uRole.0")) {
    return <span className="rounded-full  text-purple-500 w-fit text-center px-2  text-xs font-semibold">{t("uRole.0")}</span>;
  } else if (params.value === t("uRole.1")) {
    return <span className="rounded-full   text-orange-500 w-fit text-center px-2  text-xs font-semibold">{t("uRole.1")}</span>;
  } else if (params.value === t("uRole.3")) {
    return <span className="rounded-full  text-emerald-500 w-fit text-center px-2  text-xs font-semibold">{t("uRole.3")}</span>;
  }
};

function List(props) {
  const { t } = props;
  const [users, setUsers] = useState();
  const listUser = useSelector(listUserSelector);
  const filters = useSelector(filterSelector);
  const refresh = useRefreshToken();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const dispacth = useDispatch();
  const [isFiltered, setIsFiltered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dataSteps, setDataSteps] = useState(false);
  const [emailSelected, setEmailSelected] = useState("");

  console.log("List", listUser);
  //internal component for progress bar
  const Percentage = ({ number, data, email }) => (
    <div
      className="max-w-md w-full  bg-slate-200 rounded text-center relative cursor-pointer"
      onClick={() => {
        setIsOpen(true);
        setDataSteps(data);
        setEmailSelected(email);
      }}
    >
      <div className={`z-9 absolute ${number < 40 ? "bg-red-400" : number >= 40 && number <= 70 ? "bg-orange-400" : "bg-emerald-400"} rounded text-center  h-full`} style={{ width: `${number}%` }} />
      <span className="z-10 relative dark:text-white">{number}%</span>
    </div>
  );

  //const MyIndicator = ({ value }) => { value ? (<CheckCircleIcon className='w-4 h-4 text-green-500' />) : (<XCircleIcon />) }

  const MyIndicator = ({ value }) => {
    if (!value) return <XCircleIcon className="w-4 h-4 text-red-500" />;
    return <CheckCircleIcon className="w-4 h-4 text-green-500" />;
  };

  const auth = useSelector(authSelector);

  // Support
  const column = [
    { field: "id", headerName: "ID", type: "number", headerClassName: "dark:text-slate-100", hide: true },

    { field: "lastName", headerName: t("label.lastName"), width: 150, headerClassName: "dark:text-slate-100" },
    {
      field: "firstName",
      headerName: t("label.firstName"),
      width: 150,
      headerClassName: "dark:text-slate-100",
    },
    {
      field: "status",
      headerName: t("label.status"),
      headerClassName: "dark:text-slate-100",

      valueGetter: (params) => {
        let parameter = params.value;
        let cell = "";
        if (parameter == 0) {
          cell = t("uRole.0");
        } else if (parameter == 1) {
          cell = t("uStatus.1");
        } else if (parameter == 2) {
          cell = t("uStatus.2");
        } else if (parameter == 3) {
          cell = t("uStatus.3");
        } else if (parameter == 4) {
          cell = t("uStatus.4");
        } else if (parameter == 5) {
          cell = t("uStatus.5");
        } else if (parameter == 6) {
          cell = t("uStatus.6");
        } else if (parameter == 7) {
          cell = t("uStatus.7");
        } else if (parameter == 8) {
          cell = t("uStatus.8");
        } else if (parameter == 9) {
          cell = t("uStatus.9");
        } else if (parameter == 10) {
          cell = t("uStatus.10");
        } else {
          cell = "Check";
        }
        return cell;
      },
      renderCell: (params) => {
        return <ComponentChip params={params} t={t} />;
      },
    },
    {
      field: "isSousCompte",
      headerName: t("label.subAccount"),
      headerClassName: "dark:text-slate-100",
      valueGetter: (params) => {
        if (params.row.details) {
          if (params.row.details.isSousCompte == false) return "Non";
          return "Oui";
        } else {
          return null;
        }
      },

      renderCell: (cellValues) => {
        if (cellValues.row.details) {
          if (cellValues.row.details.isSousCompte == true) {
            return <CheckIcon className="h-4 w-4 mx-auto text-green-500" />;
          } else if (cellValues.row.details.isSousCompte == false) {
            return <XIcon className="h-4 w-4 mx-auto text-red-500" />;
          }
        } else {
          return <XIcon className="h-4 w-4 mx-auto text-red-500" />;
        }
      },
    },
    {
      field: "startSubscription",
      headerName: t("label.startSubscription"),
      width: 100,
      type: "date",
      headerClassName: "dark:text-slate-100",
      valueGetter: (params) => {
        if (params.row.details) {
          const date = moment(params.row.details.startSubscription).utcOffset("+00:00");
          return moment(date).format("DD/MM/YYYY");
        } else {
          return null;
        }
      },

      renderCell: (cellValues) => {
        if (cellValues.row.details) {
          return moment(cellValues.row.details.startSubscription, "DD-MM-YYYY").format("DD/MM/YYYY");
        } else {
          return null;
        }
      },
    },
    {
      field: "percentage",
      headerName: "Achevé",
      width: 100,
      headerClassName: "dark:text-slate-100",
      headerAlign: "center",
      valueGetter: (params) => {
        if (params.row.steps) {
          return params.row.steps.percentage;
        } else {
          return 0;
        }
      },
      renderCell: (cellValues) => {
        if (cellValues.row.steps) {
          return <Percentage number={cellValues.row.steps.percentage} data={cellValues.row.steps.details} email={cellValues.row.email} />;
        } else {
          return null;
        }
      },
    },
    {
      field: "vueClient",
      headerName: "Vue Client",
      sortable: false,
      filterable: false,
      disableExport: true,
      headerClassName: "dark:text-slate-100",

      renderCell: (cellValues) => {
        return (
          <Controls.Button
            xsFont
            small
            variant="none"
            color={"text-primary dark:text-slate-100 hover:text-secondary"}
            text={"Voir Détails"}
            onClick={(event) => {
              //handleClick(event, cellValues);
              navigate(`/dashboard/${cellValues.row.id}`);
              //console.log(cellValues.row.id)
            }}
          />
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      filterable: false,
      disableExport: true,
      headerClassName: "dark:text-slate-100",

      renderCell: (cellValues) => {
        return (
          <Controls.Button
            xsFont
            small
            variant="none"
            color={"text-primary dark:text-slate-100 hover:text-secondary"}
            text={t("button.edit")}
            onClick={(event) => {
              //handleClick(event, cellValues);
              navigate(`/support/user/${cellValues.row.id}`);
              //console.log(cellValues.row.id)
            }}
          />
        );
      },
    },
    {
      field: "securisation",
      headerName: "Sécurisation",
      sortable: false,
      filterable: false,
      disableExport: true,
      headerClassName: "dark:text-slate-100",

      renderCell: (cellValues) => {
        return (
          <Controls.Button
            xsFont
            small
            variant="none"
            color={"text-primary dark:text-slate-100 hover:text-secondary"}
            text={t("button.edit")}
            onClick={(event) => {
              //handleClick(event, cellValues);
              navigate(`/support/secure/${cellValues.row.id}`, {
                state: {
                  fullName: `${cellValues.row.firstName} ${cellValues.row.lastName} `,
                  email: cellValues.row.email,
                  status: cellValues.row.status,
                },
              });
              console.log(cellValues.row.id);
            }}
          />
        );
      },
    },
  ];

  const columnAdmin = [
    { field: "id", headerName: "ID", type: "number" },

    { field: "lastName", headerName: t("label.lastName"), headerClassName: "dark:text-slate-100" },
    {
      field: "firstName",
      headerName: t("label.firstName"),
      headerClassName: "dark:text-slate-100",
    },
    {
      field: "status",
      headerName: t("label.status"),
      width: 150,
      headerClassName: "dark:text-slate-100",

      valueGetter: (params) => {
        let parameter = params.value;
        let cell = "";
        if (parameter == 0) {
          cell = t("uStatus.0");
        } else if (parameter == 1) {
          cell = t("uStatus.1");
        } else if (parameter == 2) {
          cell = t("uStatus.2");
        } else if (parameter == 3) {
          cell = t("uStatus.3");
        } else if (parameter == 4) {
          cell = t("uStatus.4");
        } else if (parameter == 5) {
          cell = t("uStatus.5");
        } else if (parameter == 6) {
          cell = t("uStatus.6");
        } else if (parameter == 7) {
          cell = t("uStatus.7");
        } else if (parameter == 8) {
          cell = t("uStatus.8");
        } else if (parameter == 9) {
          cell = t("uStatus.9");
        } else if (parameter == 10) {
          cell = t("uStatus.10");
        } else {
          cell = "Check";
        }
        return cell;
      },
      renderCell: (params) => {
        return <ComponentChip params={params} t={t} />;
      },
    },
    {
      field: "isSousCompte",
      headerName: t("label.subAccount"),
      headerClassName: "dark:text-slate-100",

      valueGetter: (params) => {
        if (params.row.details) {
          if (params.row.details.isSousCompte == false) return "Non";
          return "Oui";
        } else {
          return null;
        }
      },

      renderCell: (cellValues) => {
        if (cellValues.row.details) {
          if (cellValues.row.details.isSousCompte == true) {
            return <CheckIcon className="h-4 w-4 mx-auto text-green-500" />;
          } else if (cellValues.row.details.isSousCompte == false) {
            return <XIcon className="h-4 w-4 mx-auto text-red-500" />;
          }
        } else {
          return <XIcon className="h-4 w-4 mx-auto text-red-500" />;
        }
      },
    },
    {
      field: "startSubscription",
      headerName: t("label.startSubscription"),
      width: 150,
      type: "date",
      headerClassName: "dark:text-slate-100",

      valueGetter: (params) => {
        if (params.row.details) {
          const date = moment(params.row.details.startSubscription).utcOffset("+04:00");
          return moment(date).format("DD/MM/YYYY");
        } else {
          return null;
        }
      },

      renderCell: (cellValues) => {
        if (cellValues.row.details) {
          return moment(cellValues.row.details.startSubscription, "DD-MM-YYYY").format("DD/MM/YYYY");
        } else {
          return null;
        }
      },
    },
    {
      field: "percentage",
      headerName: "Achevé",
      width: 100,
      headerClassName: "dark:text-slate-100",
      headerAlign: "center",
      valueGetter: (params) => {
        if (params.row.steps) {
          return params.row.steps.percentage;
        } else {
          return 0;
        }
      },
      renderCell: (cellValues) => {
        if (cellValues.row.steps) {
          return <Percentage number={cellValues.row.steps.percentage} data={cellValues.row.steps.details} email={cellValues.row.email} />;
        } else {
          return null;
        }
      },
    },
    {
      field: "lastBalance",
      headerName: "$ Dernier Rééq",
      width: 100,
      headerClassName: "dark:text-slate-100",
      headerAlign: "center",
      type: "number",
      valueGetter: (params) => {
        if (params.row.lastBalance) {
          return params.row.lastBalance;
        } else {
          return 0;
        }
      },
      renderCell: (cellValues) => {
        if (cellValues.row.lastBalance) {
          return cellValues.row.lastBalance;
        } else {
          return "-";
        }
      },
    },
    {
      field: "action",
      headerName: "Vue Client",
      sortable: false,
      filterable: false,
      disableExport: true,
      headerClassName: "dark:text-slate-100",

      renderCell: (cellValues) => {
        return (
          <Controls.Button
            xsFont
            small
            variant="none"
            color={"text-primary dark:text-slate-100 hover:text-secondary"}
            text={"Voir Détails"}
            onClick={(event) => {
              //handleClick(event, cellValues);
              navigate(`/dashboard/${cellValues.row.id}`);
              //console.log(cellValues.row.id)
            }}
          />
        );
      },
    },
  ];
  const columnSuperAdmin = [
    { field: "id", headerName: "ID", type: "number", headerClassName: "dark:text-slate-100", hide: true },
    { field: "lastName", headerName: t("label.lastName"), headerClassName: "dark:text-slate-100" , width: 150 },
    {
      field: "firstName",
      headerName: t("label.firstName"),
      headerClassName: "dark:text-slate-100",
    },
    {
      field: "status",
      headerName: t("label.status"),
      width: 150,
      headerClassName: "dark:text-slate-100",

      valueGetter: (params) => {
        let parameter = params.value;
        let cell = "";
        if (parameter == 0) {
          cell = t("uStatus.0");
        } else if (parameter == 1) {
          cell = t("uStatus.1");
        } else if (parameter == 2) {
          cell = t("uStatus.2");
        } else if (parameter == 3) {
          cell = t("uStatus.3");
        } else if (parameter == 4) {
          cell = t("uStatus.4");
        } else if (parameter == 5) {
          cell = t("uStatus.5");
        } else if (parameter == 6) {
          cell = t("uStatus.6");
        } else if (parameter == 7) {
          cell = t("uStatus.7");
        } else if (parameter == 8) {
          cell = t("uStatus.8");
        } else if (parameter == 9) {
          cell = t("uStatus.9");
        } else if (parameter == 10) {
          cell = t("uStatus.10");
        } else {
          cell = "Check";
        }
        return cell;
      },
      renderCell: (params) => {
        return <ComponentChip params={params} t={t} />;
      },
    },
    {
      field: "role",
      headerName: t("label.role"),
      width: 100,
      headerClassName: "dark:text-slate-100",

      valueGetter: (params) => {
        let parameter = params.value;
        let cell = "";
        if (parameter == 2) {
          cell = t("uRole.2");
        } else if (parameter == 4) {
          cell = t("uRole.4");
        } else if (parameter == 0) {
          cell = t("uRole.0");
        } else if (parameter == 1) {
          cell = t("uRole.1");
        } else if (parameter == 3) {
          cell = t("uRole.3");
        }
        return cell;
      },
      renderCell: (params) => {
        return <ComponentRole params={params} t={t} />;
      },
    },
    {
      field: "createdAt",
      headerName: t(`label.creationDate`),
      width: 200,
      headerClassName: "dark:text-slate-100",
      valueGetter: (params) => {
        if (params.row.createdAt) {
          const date = moment(params.row.createdAt);
          return moment(date).format("DD/MM/YYYY");
        } else {
          return null;
        }
      },

      renderCell: (cellValues) => {
        if (cellValues.row.createdAt) {
          return moment(cellValues.row.createdAt).format("DD/MM/YYYY - LTS");
        } else {
          return null;
        }
      },
    },
    {
      field: "updatedAt",
      headerName: t(`label.lastLogin`),
      width: 200,
      headerClassName: "dark:text-slate-100",
      valueGetter: (params) => {
        if (params.row.updatedAt) {
          const date = moment(params.row.updatedAt);
          return moment(date).format("DD/MM/YYYY ");
        } else {
          return null;
        }
      },

      renderCell: (cellValues) => {
        if (cellValues.row.updatedAt) {
          return moment(cellValues.row.updatedAt).format("DD/MM/YYYY -LTS");
        } else {
          return null;
        }
      },
    },
  ];
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        // const response = await axiosPrivate.get(`/api/users/genericFindAll`, {
        //     signal: controller.signal
        // });
        const response = await axiosPrivate.get(`/api/users/userslist`, {
          signal: controller.signal,
        });
        isMounted && setUsers(response.data);

        console.log("USER", response.data);
        if (response.data) {
          dispacth(setallUsers(response.data.sort((a, b) => b.id - a.id)));
        }
      } catch (error) {
        //console.log(error)

        if (error?.response?.status != 403) {
          navigate("/login", { state: { from: location }, replace: true });
        } /*
                setTimeout(() => {

                }, 5000)*/
      }
    };

    getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <>
      {users ? (
        <DataGrid
          components={{
            Toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          initialState={{
            sorting: {
              sortModel: [{ field: "id", sort: "desc" }],
            },
          }}
          rows={isFiltered ? listUser.filter((u) => filters.includes(moment(u.createdAt).format("DD-MM-YYYY"))) : listUser}
          columns={auth.role.includes(process.env.REACT_APP_SUPPORT) ? column : auth.role.includes(process.env.REACT_APP_SUPADMIN) ? columnSuperAdmin : columnAdmin}
          //pagination={true}
          onCellClick={(value) => console.log("yes", value.id)}
          disableColumnSelector
          disableDensitySelector
          getRowClassName={(params) => `text-slate-800 dark:text-slate-100`}
          density="compact"
          className="text-red-500"
          autoPageSize
        />
      ) : (
        <Loading />
      )}
    </>
  );
}

export default List;
