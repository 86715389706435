import React, { Fragment, useEffect } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { logout } from "../../features/authSlice"
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';


function MyPopover(props) {
  const { children, title, isOpen, setIsOpen, t } = props;
  const dispacth = useDispatch();
  const navigate = useNavigate()
  const goodBye = () => {
    dispacth(logout())
    navigate("/login")
  }
  useEffect(() => {
    if (isOpen) {
      setTimeout(function () { setIsOpen(false) }, 5000);
    }

  }, [isOpen == true])
  return (
    <div className='fixed top-9 w-60 max-w-sm  right-0'>
      <Popover className="relative " >
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
          show={isOpen}
        >
          <Popover.Panel className="absolute bg-red-500 right-0 z-10 mt-3 w-full max-w-xs">

            {children}

            {/* <MyPopover isOpen={isOpen} setIsOpen={setIsOpen} t={t}>
                        <div className="bg-gray-50 p-1">
                            <div className="flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring  focus-visible:ring-opacity-50"
                            >
                                <span className="flex items-center space-x-2" onClick={goodBye} >
                                    <span className="text-sm font-medium text-gray-900">
                                        <LogoutIcon className='w-5 h-5 dark:text-slate-200 ' />
                                    </span>
                                    <span className="block text-sm text-gray-500">
                                        {t('logout')}
                                    </span>
                                </span>

                            </div>
                        </div>
                    </MyPopover> */}

            {/* <div className="bg-gray-50 p-4">
              <a
                href="##"
                className="flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
              >
                <span className="flex items-center">
                  <span className="text-sm font-medium text-gray-900">
                    Documentation
                  </span>
                </span>
                <span className="block text-sm text-gray-500">
                  Start integrating products and tools
                </span>
              </a>
            </div> */}

            {/* <div className="bg-gray-50 p-1">
              <div
                
                className="flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring  focus-visible:ring-opacity-50"
              >
                <span className="flex items-center space-x-2" onClick={goodBye} >
                  <span className="text-sm font-medium text-gray-900">
                    <LogoutIcon className='w-5 h-5 dark:text-slate-200 ' />
                  </span>
                  <span className="block text-sm text-gray-500">
                    {t('logout')}
                  </span>
                </span>

              </div>
            </div> */}

          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  )
}

export default MyPopover