import React from 'react'
import { Outlet } from "react-router-dom"
import Header from './Header'
function Layout(props) {
    const { t } = props
    return (
        <main className='bg-slate-200 dark:bg-slate-900 flex flex-col min-h-screen'>
            <Header t={t} />
            <Outlet />
        </main>
    )
}

export default Layout