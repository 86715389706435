import { ArrowNarrowLeftIcon, ShieldCheckIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { useFOrm, Form } from "../components/useFOrm";
import Controls from "../components/controls/Controls";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { authSelector } from "../features/authSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const initialFvalues = {
  password: "",
};

function AuthScreen(props) {
  const { t } = props;
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [showMdpInput, setShowMdpInput] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [code, setCode] = useState();
  const [codeError, setCodeError] = useState();
  const [showCodeInput, setShowCodeInput] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(authSelector);
  const goBack = () => navigate(-1);
  const handleMdp = () => {
    setShowMdpInput(true);
    console.log(showMdpInput);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("password" in fieldValues) temp.password = fieldValues.password ? "" : t("error.required");
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialFvalues, true, validate);
  const formLogin = [
    {
      type: "password",
      name: "password",
      label: t("label.password"),
      placeholder: "******",
      password: true,
      required: true,
    },
  ];

  const verifyPassword = async () => {
    if (validate()) {
      try {
        const dataToCheck = {
          password: values.password,
        };
        const result = await axiosPrivate.post(`api/users/reauth`, dataToCheck);
        if (result) {
          setQrCode(result.data);
          setShowCodeInput(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const deleteTfa = async () => {
    if (validate()) {
      try {
        const dataToCheck = {
          password: values.password,
        };
        const result = await axiosPrivate.post(`api/users/deleteTfa`, dataToCheck);
        if (result.data.msg == "delete") {
          setUser({
            ...user,
            tfa: false,
          });
          setShowMdpInput(false);
          toast.warning("2FA désactivé");
          navigate(-1);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleVerifyCode = async () => {
    try {
      if (!code) throw t("error.required");
      const response = await axiosPrivate.get(`/api/users/verifyTfa/${code}`);
      if (response.data) {
        console.log("OOOKKK");
        setUser({
          ...user,
          tfa: response.data,
        });
        setShowCodeInput(false);
        toast.success("2FA activé");
        navigate(-1);
      } else {
        toast.error("code à 6 chiffres incorrect");
      }
    } catch (error) {
      setCodeError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (code) {
      setCodeError("");
    }
  }, [code]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getOneUser = async () => {
      try {
        const response = await axiosPrivate.get(`/api/users/findBy/${auth.id}`, {
          signal: controller.signal,
        });
        console.log("====users", response.data);
        isMounted && setUser(response.data);
      } catch (error) {
        toast.error("error");
        console.log(error);
      }
    };
    getOneUser();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);
  return (
    <div className=" mx-2 md:mx-auto bg-slate-50  mt-4 pt-2 px-4  md:w-[70%] py-4">
      <div className="flex items-center">
        <div className="hover:bg-slate-300 rounded-full w-fit p-1.5 ">
          <ArrowNarrowLeftIcon className="h-4 w-4 hover:cursor-pointer" onClick={goBack} />
        </div>
        <div className="text-sm font-medium ">Sécurité du compte</div>
      </div>

      <div className="  px-2 ">
        {/* <div className="flex items-center gap-x-1 pt-2">
          <div className="text-sm underline underline-offset-1">Double authentification</div>
          <CheckCircleIcon className={`${user && user.tfa ? "w-4 h-4 text-green-500" : "hidden"} `} />
        </div> */}
        <div className=" ">
          {user && user.tfa ? (
            <>
              <div className=" text-sm pt-2 ">
                <div className="flex flex-col items-center gap-y-4">
                  <div>
                    <ShieldCheckIcon className="w-8 h-8 text-green-400 " />
                  </div>
                  <div>Authentification à deux facteurs activée</div>
                  <div className="text-xs font-extralight text-center">En désactivant l'authentification à 2 facteurs, aucun code de vérification vous sera demandé à chaque connexion.</div>
                  <div className="  border border-red-400 h-fit px-6 py-1 my-2 rounded-lg hover:cursor-pointer " onClick={handleMdp}>
                    Désactiver
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className=" text-sm pt-2 ">
                <div className="flex flex-col items-center gap-y-4">
                  <div>
                    <ShieldCheckIcon className="w-8 h-8 text-sky-300 " />
                  </div>
                  <div>Utiliser l’authentification à deux facteurs</div>
                  <div className="text-xs font-extralight text-center">En activant l'authentification à 2 facteurs, un code de vérification vous sera demandé à chaque connexion.</div>
                  <div className="  border border-sky-400 h-fit px-6 py-1 my-2 rounded-lg hover:cursor-pointer " onClick={handleMdp}>
                    Activer
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className={`${showMdpInput && qrCode == "" ? "block text-sm pt-4 " : "hidden"}`}>
        <div className="pb-2">Veuillez entrer votre mot de passe pour continuer</div>
        <div className="text-xs font-light pb-2">Pour activer ou désactiver l'authentification à 2 facteurs, vous devez confirmer votre mot de passe.</div>
        <div className="pb-2 mx-6">
          {formLogin.map((item, i) => {
            return (
              <div>
                <Controls.Input fullWidth={true} label={item.label} value={values[item.name]} onChange={handleInputChange} name={item.name} type={item.type} error={errors[item.name]} />
              </div>
            );
          })}
        </div>
        <div className="text-right mr-6 mb-2">
          {user && user.tfa ? <Controls.Button onClick={deleteTfa} text="Valider" small xsFont /> : <Controls.Button onClick={verifyPassword} text="Continuer" small xsFont />}
        </div>
      </div>
      {showCodeInput && (
        <>
          <div className="mt-2 bg-slate-200 w-full h-0.5" />
          <div className="text-xs font-light mt-4 text-center">
            Veuillez scanner ce QR Code avec une application générateur de code, comme <strong>Google Authentificator</strong>.
            <p className="pt-2">Si ce n'est pas déjà fait, nous vous invitons à le télécharger.</p>
          </div>
          <div className="py-3 flex justify-center ">{qrCode && <img src={qrCode && qrCode} className="w-48 h-48 " />}</div>
          <div className="text-xs font-light text-center mt-2">Saisissez le code à 6 chiffres de votre Générateur de code ou application tierce ci-dessous.</div>
          <div className="flex gap-x-2 mt-4 items-center max-w-1/2 w-1/2 mx-auto">
            <Controls.Input type="number" label={"Code de vérification"} name="code" error={codeError} onChange={(e) => setCode(e.target.value)} />

            <div className=" text-right">
              <Controls.Button text={"OK"} onClick={handleVerifyCode} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default AuthScreen;
