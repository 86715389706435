import { createSlice, current } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = []

export const followSlice = createSlice({
    name: 'follow',
    initialState,
    reducers: {
        setFollowUp: (state, action) => {
            return action.payload;
        },

        updateFollowUp: (state, action) => {
            const newState = state
            const changedIdx = newState.findIndex(t => (t.id == action.payload.tid))
            return [
                ...newState.slice(0, changedIdx),
                {
                    ...newState[changedIdx],
                    ...action.payload
                },
                ...newState.slice(changedIdx + 1)]
        },
        updateFollowUpChild: (state, action) => {
            const newState = state
            const changedIdx = newState.findIndex(t => (t.id == action.payload.tid))
            const changedIdxChild = newState[changedIdx].t.findIndex(t => (t.id == action.payload.id))
            /* newState[changedIdx].t.forEach(element => {
                 console.log('elem', element)
             });*/

            console.log("p",changedIdx)
            console.log("c",changedIdxChild)

            return [
                ...newState.slice(0, changedIdx),
                {
                    ...newState[changedIdx],
                    t: [
                        ...newState[changedIdx].t.slice(0, changedIdxChild),
                        {
                            ...newState[changedIdx].t[changedIdxChild],
                            ...action.payload
                        },
                        ...newState[changedIdx].t.slice(changedIdxChild + 1)
                    ]

                },
                ...newState.slice(changedIdx + 1)]
        },

        updateFollowUpList: (state, action) => {
            const changedIdx = state.findIndex(u => (u.id == action.payload.uid))
            //const newArray = [ current(state)]
            return [
                ...state.slice(0, changedIdx),
                {
                    ...state[changedIdx],
                    ...action.payload
                },
                ...state.slice(changedIdx + 1)]
        }
    }
});

export const { setFollowUp, updateFollowUp, updateFollowUpList, updateFollowUpChild } = followSlice.actions
export const selectFollowUpCard = (state) => state.follow;
export default followSlice.reducer
