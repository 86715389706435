import React, { useState } from "react";
import { useFOrm, Form } from "../useFOrm";
import Controls from "../controls/Controls";
import { Grid } from "@mui/material";
import { login } from "../../features/authSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import axiosGlobal from "../../axios";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import * as services from "../../utils/service.js";
import CheckPointScreen from "../../screens/CheckPointScreen";
const initialFvalues = {
  email: "",
  password: "",
};

function LoginForm(props) {
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const cookies = new Cookies();
  const { t } = props;
  const dispacth = useDispatch();
  const [hasTfa, setHasTfa] = useState(false);
  const [userDataLogin, setUserDataLogin] = useState();
  const [tempToken, setTempToken] = useState();
  const [isFirstLogin, setIsFirstLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(false)
  //const [hideForm, setHideForm] = useState(false)
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues) temp.email = fieldValues.email ? "" : t("error.required");
    if ("password" in fieldValues) temp.password = fieldValues.password ? "" : t("error.required");
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, resetForm, handleInputChange } = useFOrm(initialFvalues, true, validate);

  const formLogin = [
    {
      type: "email",
      name: "email",
      label: "Nom d'utilisateur",
      placeholder: "Enter your email",
      password: false,
      required: true,
    },
    {
      type: "password",
      name: "password",
      label: t("label.password"),
      placeholder: "******",
      password: true,
      required: true,
    },
  ];

  const formLoginCheckEmail = [
    {
      type: "email",
      name: "email",
      label: "Nom d'utilisateur",
      placeholder: "Enter your email",
      password: false,
      required: true,
    }
  ];

  let navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setIsLoading(true)
      const data = {
        email: values.email,
        password: values.password,
      };
      try {
        const response = await axiosGlobal.post("/api/users/login", data);
        if (response) {
          const dataLogin = {
            token: "",
            role: response.data.role,
            email: response.data.email,
            name: response.data.name,
            id: response.data.userId,
            isFirstLogin: response.data.isFirstLogin,
          };
          const cryptToken = services.encryptCB(response.data.token);
          setUserDataLogin(dataLogin);
          if (response.data.tfa) {
            /*navigate({
              pathname: '/checkpoint',
              state: {
                uid: response.data.userId
              }
            })*/
            setHasTfa(response.data.tfa);
            setTempToken(cryptToken);
          } else {
            //cookies.set('refreshToken', response.data.refreshToken, { path: '/' });
            if (!dataLogin.isFirstLogin) {
              dispacth(login(dataLogin));
              const rolesArray = dataLogin.role.split(",");

              switch (true) {
                case rolesArray.length == 1 && rolesArray.includes("4"):
                  localStorage.setItem("token", cryptToken);
                  navigate("/affiliate");
                  break;
                case rolesArray.includes("0"):
                  localStorage.setItem("token", cryptToken);
                  navigate("/hello");
                  break;
                case rolesArray.includes("1"):
                  localStorage.setItem("token", cryptToken);
                  navigate("/hello");
                  break;
                case rolesArray.includes("2"):
                  localStorage.setItem("token", cryptToken);
                  navigate("/rebalancing");
                  break;
                case rolesArray.includes("3"):
                  localStorage.setItem("token", cryptToken);
                  navigate("/hello");
                  break;
                default:
                  navigate(from, { replace: true });
                  break;
              }
            } else {
              navigate("/reset", { state: { isFirstLogin: dataLogin.isFirstLogin } });
            }
          }
          setIsLoading(false)
        }
      } catch (error) {
        toast.error("Email ou Mot de passe incorrect");
        setIsLoading(false)
      }
    } else {
    }
  };

  const checkFirstLogin = async (e) => {
    try {
      setIsLoading(true)
      if (!values.email) throw 'Error'
      try {

        const data = {
          email: values.email
        };
        const response = await axiosGlobal.post("/api/users/check", data)
        // console.log(response.data)
        setIsFirstLogin(response.data.isFirstLogin)
        if (response.data.isFirstLogin) navigate("/reset", { state: { isFirstLogin: response.data.isFirstLogin } });
        setIsLoading(false)
      } catch (error) {
        //toast.error('Impossible de trouver votre compte');
        setErrors({ email: t("error.notFound") })
        setIsLoading(false)
      }
    } catch (error) {
      setErrors({ email: t("error.invalidEmail") })
      setIsLoading(false)
    }
  }

  return (
    <>
      {!hasTfa && (
        <Form onSubmit={handleSubmit} noValidate>

          {isFirstLogin &&
            <div className='space-y-2'>
              {formLoginCheckEmail.map((item, i) => {
                return (
                  <Grid item xs={12} key={i}>
                    <Controls.Input fullWidth={true} label={item.label} value={values[item.name]} onChange={handleInputChange} name={item.name} type={item.type} error={errors[item.name]} />
                  </Grid>
                );
              })}
              <Controls.Button type='button' onClick={checkFirstLogin} text={t("button.next")} widthFull isLoading={isLoading} />
            </div>
          }
          {!isFirstLogin &&
            <div>
              <Grid container spacing={2}>
                {formLogin.map((item, i) => {
                  return (
                    <Grid item xs={12} key={i}>
                      <Controls.Input fullWidth={true} label={item.label} value={values[item.name]} onChange={handleInputChange} name={item.name} type={item.type} error={errors[item.name]} />
                    </Grid>
                  );
                })}
              </Grid>

              <div style={{ marginTop: "20px" }}>
                {/* <Controls.Button type="submit" text={t('button.login')} fullWidth /> */}
                <Controls.Button type="submit" text={t("button.login")} widthFull isLoading={isLoading} />
              </div>
            </div>
          }


        </Form>
      )}
      {hasTfa && <CheckPointScreen token={tempToken} t={t} dataLogin={userDataLogin} />}
    </>
  );
}

export default LoginForm;
