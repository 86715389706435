import React, { useEffect, useState } from "react";
import * as sideMenus from "../utils/sideMenus";
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";
import TableauGrid from "../components/TableauGrid";
import moment from "moment";
import { useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { authSelector } from "../features/authSlice";
import EmailPreview from "../components/EmailPreview";
import Controls from "../components/controls/Controls";
import Loading from "../components/Loading";
import BannerInfo from "../components/BannerInfo";
import DialogBox from "../components/DialogBox";
import ConstituentScreen from './ConstituentScreen';

function ClientRebalancingScreen(props) {
  const { t } = props;
  const axiosPrivate = useAxiosPrivate();
  let [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const auth = useSelector(authSelector);
  const navigate = useNavigate();
  const { id } = useParams(); // maka id
  const [selectedRow, setSelectedRow] = useState();
  const [allEmail, setAllEmail] = useState();
  const [isAfterJune, setIsAfterJune] = useState(false);
  const [hasActiveZap, setHasActiveZap] = useState(false);
  const columns = [
    {
      field: "id",
      headerName: "id",
      hide: true,
    },
    {
      field: "date",
      headerName: t("label.date"),
      type: "date",
      renderCell: (cellValues) => {
        if (cellValues.row.date) {
          return moment(cellValues.row.date, "DD-MM-YYYY").format("DD/MM/YYYY");
        } else {
          return null;
        }
      },
    },
    {
      field: "endCapital",
      headerName: t("label.endCapital"),
      flex: 1,
    },
    {
      field: "capitalToRebalance",
      headerName: t("label.capitalToRebalance"),
      flex: 1,
    },
    {
      field: "totalRebalancedValue",
      headerName: t("label.totalRebalancedValue"),
      flex: 1,
    },
    {
      field: "startCapitalCurrentMonth",
      headerName: t("label.startCapitalCurrentMonth"),
      flex: 1,
    },

    {
      field: "action",
      headerName: t(`label.action`),
      sortable: false,
      filterable: false,
      disableExport: true,
      headerClassName: "dark:text-slate-100",
      renderCell: (cellValues) => {
        return (
          <Controls.Button
            xsFont
            small
            variant="none"
            color={"text-primary dark:text-slate-100 hover:text-secondary"}
            text={"Aperçu"}
            onClick={(event) => {
              //handleClick(event, cellValues);
              // navigate(`/support/user/${cellValues.row.id}`);
              //console.log(cellValues.row.id)
              const dataToShow = allEmail.filter((a) => a.id === cellValues.row.id);
              setSelectedRow(dataToShow[0]);
              setIsOpen(true);
            }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getIsfirstrabalancing = async (myId) => {
      try {
        const resp = await axiosPrivate.get(`/api/rebalancing/isFirstrebalancing/${myId}`, {
          signal: controller.signal,
        });
        isMounted && setIsAfterJune(resp.data);
      } catch (error) { }
    };

    const getUser = async (myId) => {
      try {
        const { data } = await axiosPrivate.get(`/api/emails/find/${myId}`, {
          signal: controller.signal,
        });
        //console.log("test", data);
        isMounted && setAllEmail(data);
      } catch (error) {
        if (error.response.status != 403) {
          //navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };



    if (auth?.role.includes("2") || auth?.role.includes("4")) {
      getUser(auth?.id);
      getIsfirstrabalancing(auth?.id);

    } else {
      getUser(id);
      getIsfirstrabalancing(id);

    }

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <>
      <TopBar menus={sideMenus.menusClient(t, id, auth.role)} />

      <div className="flex flex-row flex-grow">
        <Sidebar menus={sideMenus.menusClient(t, id, auth.role)} />

        <div className="flex flex-row flex-1 mt-2 md:ml-52 ">
          <div className="flex flex-col w-full p-4 ">
            <div className="flex items-center h-auto">
              <div className='flex-1'>
                <h1 className="font-semibold text-2xl text-slate-700 dark:text-white">{t(`label.rebalancing`)}</h1>
              </div>
              {auth.role.includes(process.env.REACT_APP_SUPADMIN) ? (
                <div>
                  <ConstituentScreen />
                </div>
              ) : null}
            </div>
            <hr />


            <div>
              <div className="h-[76vh] bg-slate-100">
                {allEmail && allEmail ? <TableauGrid column={columns} dataRows={allEmail && allEmail} /> : <Loading />}
              </div>
              <DialogBox
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                // title={"Détails"}
                customClassName={"md:w-1/2 w-full min-h-[300px]"}
              >
                <div className="h-fit bg-slate-100 my-2 p-2">
                  <EmailPreview selectedRow={(selectedRow && selectedRow) || ""} hasActiveZap={hasActiveZap} />
                </div>
              </DialogBox>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientRebalancingScreen;
