import React, { useState, useEffect } from "react";
import useRefreshToken from "../../hooks/useRefreshToken";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import { DataGrid, frFR, GridToolbar } from "@mui/x-data-grid";
import Controls from "../controls/Controls";

//Slices
import { setallUsers, listUserSelector, removeUser } from "../../features/listUserSlice";
import { addFilter, filterSelector } from "../../features/filterSlice";
import { useDispatch, useSelector } from "react-redux";
import DialogBox from "../DialogBox";
import { XIcon, CheckIcon, CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import moment from "moment";
import Loading from "../Loading";
import { authSelector } from "../../features/authSlice";
import { toast } from "react-toastify";
import useCountDown from '../../hooks/useCountDown';
import axios from '../../axios';

function Users(props) {
  const { t, fetchAllUser, reset } = props;
  const [users, setUsers] = useState();
  const listUser = useSelector(listUserSelector);
  const filters = useSelector(filterSelector);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const dispacth = useDispatch();
  const [isFiltered, setIsFiltered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dataSteps, setDataSteps] = useState(false);
  const [emailSelected, setEmailSelected] = useState("");
  const [reeqProcessing, setReeqProcessing] = useState(false);
  const [stopOpen, setStopOpen] = useState(false);
  const [selectedID, setSelectedId] = useState(0);
  const { secondsLeft, start } = useCountDown()
  const MyIndicator = ({ value }) => {
    if (!value) return <XCircleIcon className="w-4 h-4 text-red-500" />;
    return <CheckCircleIcon className="w-4 h-4 text-green-500" />;
  };

  const auth = useSelector(authSelector);

  const [total, setTotal] = useState(users?.length);
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        // const response = await axiosPrivate.get(`/api/users/genericFindAll`, {
        //     signal: controller.signal
        // });
        const response = await axiosPrivate.get(`/api/reporting/findAllUsers`, {
          signal: controller.signal,
        });

        const allStatus = response.data.all.filter((m) => m.status == 2);
        if (allStatus.length > 0) isMounted && setReeqProcessing(true);

        console.log("ewrwf", allStatus);
        isMounted && setUsers(response.data.all);

        console.log("USER", response.data.all);
        if (response.data) {
          dispacth(setallUsers(response.data.all.sort((a, b) => b.id - a.id)));
        }
      } catch (error) {
        //console.log(error)

        if (error.response.status != 403) {
          navigate("/login", { state: { from: location }, replace: true });
        }
        /* setTimeout(() => {

         }, 5000)*/
      }
    };

    getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  //
  const handleStop = async (uid) => {
    console.log("🚀 ~ file: index.js:86 ~ handleStop ~ uid:", uid)
    try {
      toast.success(uid, 'User stop')
      //update user details to cb user
      axios.put(`/api/users/${uid}`, { role: 4 })
      dispacth(removeUser(uid))

    } catch (error) {
      toast.error("ERROR")
      console.log("🚀 ~ file: index.js:89 ~ handleStop ~ error:", error)
    }
  }

  // Support
  const column = [
    { field: "id", headerName: "ID", type: "number", headerClassName: "dark:text-slate-100", hide: true },

    {
      field: "lastName",
      width: 150,
      headerName: t("label.lastName"),
      headerClassName: "dark:text-slate-100",
    },
    {
      field: "firstName",
      width: 150,
      headerName: t("label.firstName"),
      headerClassName: "dark:text-slate-100",
    },
    {
      field: "startSubscription",
      headerName: t("label.startSubscription"),
      width: 150,
      type: "date",
      //valueFormatter: params => moment(params?.row.details.startSubscription ,'DD-MM-YYYY').format("DD/MM/YYYY hh:mm A"),
      valueGetter: (params) => {
        if (params.row.details) {
          const date = new Date(moment(params.row.details.startSubscription, "DD-MM-YYYY").format("YYYY-MM-DD"));
          return date.toLocaleDateString();
        } else {
          return null;
        }
      },

      renderCell: (cellValues) => {
        if (cellValues?.row?.details) {
          return moment((cellValues?.row?.details?.startSubscription).trim(), "DD-MM-YYYY").format("DD/MM/YYYY");
        } else {
          return null;
        }
      },
    },

    {
      field: "lastBalance",
      headerName: "$ Dernier Rééq",
      width: 150,
      headerClassName: "dark:text-slate-100",
      headerAlign: "center",
      type: "number",
      valueGetter: (params) => {
        if (params.row.lastBalance) {
          return params.row.lastBalance;
        } else {
          return 0;
        }
      },
      renderCell: (cellValues) => {
        if (cellValues.row.lastBalance) {
          return cellValues.row.lastBalance;
        } else {
          return "-";
        }
      },
    },

    {
      field: "action",
      headerName: "Vue Client",
      sortable: false,
      filterable: false,
      disableExport: true,
      headerClassName: "dark:text-slate-100",

      renderCell: (cellValues) => {
        return (
          <Controls.Button
            xsFont
            small
            variant="none"
            color={"text-primary dark:text-slate-100 hover:text-secondary"}
            text={"Voir Détails"}
            onClick={(event) => {
              //handleClick(event, cellValues);
              navigate(`/rebalancing/${cellValues.row.id}`);
              //console.log(cellValues.row.id)
            }}
          />
        );
      },
    },
    {
      field: "to",
      headerName: "Action",
      sortable: false,
      filterable: false,
      disableExport: true,
      headerClassName: "dark:text-slate-100",

      renderCell: (cellValues) => {
        return (
          <Controls.Button
            xsFont
            small
            variant="none"
            color={"text-primary dark:text-slate-100 hover:text-secondary"}
            text={t("button.edit")}
            onClick={(event) => {
              //handleClick(event, cellValues);
              navigate(`/support/user/${cellValues.row.id}`);
              //console.log(cellValues.row.id)
            }}
          />
        );
      },
    },
    {
      field: "reeq",
      headerName: "Rééquilibrage",
      sortable: false,
      filterable: false,
      disableExport: true,
      headerClassName: "dark:text-slate-100",

      renderCell: (cellValues) => {
        return (
          <>
            {reeqProcessing && reeqProcessing ? (
              <Controls.Button
                xsFont
                small
                disabled={true}
                variant="none"
                color={"text-white bg-indigo-400 hover:bg-indigo-600  dark:text-slate-100 "}
                text={t("Rééquilibrer")}
              /* onClick={async (event) => {
               try {
                 toast.info("En cours");
                 //const result = await axiosPrivate.get(`/api/rebalancing/runReeq/${cellValues.row.id}`);
                 //console.log("Result", result);
                 if (result.data === "Rebalancing done") {
                   toast.success("Rééquilibrage fait");
                 }
               } catch (error) {
                 console.log(error);
                 toast.error("Erreur");
               }

             }}*/
              />
            ) : (
              <Controls.Button
                xsFont
                small
                disabled={cellValues.row.id == 1 || cellValues.row.id == 82 || cellValues.row.id == 384 ? false : true}
                variant="none"
                color={"text-white bg-indigo-400 hover:bg-indigo-600  dark:text-slate-100 "}
                text={t("Rééquilibrer")}
                onClick={async (event) => {
                  try {
                    toast.info("En cours ");
                    setReeqProcessing(true);
                    const result = await axiosPrivate.get(`/api/rebalancing/runReeq/${cellValues.row.id}`);
                    //console.log("Result", result);
                    if (result.data === "Rebalancing done") {
                      toast.success("Rééquilibrage fait");
                      setReeqProcessing(false);
                    }
                  } catch (error) {
                    console.log(error);
                    toast.error("Erreur");
                  }
                }}
              />
            )}
          </>
        );
      },
    },
  ];
  const columnAdmin = [
    { field: "id", headerName: "ID", type: "number", headerClassName: "dark:text-slate-100", hide: true },

    {
      field: "lastName",
      width: 150,
      headerName: t("label.lastName"),
      headerClassName: "dark:text-slate-100",
    },
    {
      field: "firstName",
      width: 150,
      headerName: t("label.firstName"),
      headerClassName: "dark:text-slate-100",
    },
    {
      field: "startSubscription",
      headerName: t("label.startSubscription"),
      width: 150,
      type: "date",
      //valueFormatter: params => moment(params?.row.details.startSubscription ,'DD-MM-YYYY').format("DD/MM/YYYY hh:mm A"),
      valueGetter: (params) => {
        if (params.row.details) {
          const date = new Date(moment(params.row.details.startSubscription, "DD-MM-YYYY").format("YYYY-MM-DD"));
          return date.toLocaleDateString();
        } else {
          return null;
        }
      },

      renderCell: (cellValues) => {
        if (cellValues?.row?.details) {
          return moment((cellValues?.row?.details?.startSubscription).trim(), "DD-MM-YYYY").format("DD/MM/YYYY");
        } else {
          return null;
        }
      },
    },

    {
      field: "lastBalance",
      headerName: "$ Dernier Rééq",
      width: 150,
      headerClassName: "dark:text-slate-100",
      headerAlign: "center",
      type: "number",
      valueGetter: (params) => {
        if (params.row.lastBalance) {
          return params.row.lastBalance;
        } else {
          return 0;
        }
      },
      renderCell: (cellValues) => {
        if (cellValues.row.lastBalance) {
          return cellValues.row.lastBalance;
        } else {
          return "-";
        }
      },
    },

    {
      field: "action",
      headerName: "Vue Client",
      sortable: false,
      filterable: false,
      disableExport: true,
      headerClassName: "dark:text-slate-100",

      renderCell: (cellValues) => {
        return (
          <Controls.Button
            xsFont
            small
            variant="none"
            color={"text-primary dark:text-slate-100 hover:text-secondary"}
            text={"Voir Détails"}
            onClick={(event) => {
              //handleClick(event, cellValues);
              navigate(`/rebalancing/${cellValues.row.id}`);
              //console.log(cellValues.row.id)
            }}
          />
        );
      },
    },
    {
      field: "to",
      headerName: "Action",
      sortable: false,
      filterable: false,
      disableExport: true,
      headerClassName: "dark:text-slate-100",

      renderCell: (cellValues) => {
        return (
          <Controls.Button
            xsFont
            small
            variant="none"
            color={"text-primary dark:text-slate-100 hover:text-secondary"}
            text={t("button.edit")}
            onClick={(event) => {
              //handleClick(event, cellValues);
              navigate(`/support/user/${cellValues.row.id}`);
              //console.log(cellValues.row.id)
            }}
          />
        );
      },
    },
    {
      field: "Stop",
      headerName: "",
      sortable: false,
      filterable: false,
      disableExport: true,
      headerClassName: "dark:text-slate-100",
      width: 200,

      renderCell: (cellValues) => {


        if (secondsLeft > 0 && cellValues.row.id == selectedID) {
          return (
            <Controls.Button
              xsFont
              small
              variant="none"
              color={"text-white bg-green-500 dark:text-slate-100 hover:bg-red-600"}
              text={`Confirmer (${secondsLeft})`}
              onClick={(event) => {
                handleStop(cellValues.row.id)
                start(15)
                setSelectedId(0)
              }}
            />
          )
        }
        return (
          <Controls.Button
            xsFont
            small
            disabled={auth.id == 1000 ? true : false}
            variant="none"
            color={"text-white bg-red-500 dark:text-slate-100 hover:bg-red-600"}
            text={'Stop'}
            onClick={(event) => {
              start(15)
              setSelectedId(cellValues.row.id)
            }}
          />
        );
      },
    },

  ];
  const columnSuperAdmin = [
    { field: "id", headerName: "ID", type: "number", headerClassName: "dark:text-slate-100", hide: true },

    {
      field: "lastName",
      width: 150,
      headerName: t("label.lastName"),
      headerClassName: "dark:text-slate-100",
    },
    {
      field: "firstName",
      width: 150,
      headerName: t("label.firstName"),
      headerClassName: "dark:text-slate-100",
    },
    {
      field: "startSubscription",
      headerName: t("label.startSubscription"),
      width: 150,
      type: "date",
      //valueFormatter: params => moment(params?.row.details.startSubscription ,'DD-MM-YYYY').format("DD/MM/YYYY hh:mm A"),
      valueGetter: (params) => {
        if (params.row.details) {
          const date = new Date(moment(params.row.details.startSubscription, "DD-MM-YYYY").format("YYYY-MM-DD"));
          return date.toLocaleDateString();
        } else {
          return null;
        }
      },

      renderCell: (cellValues) => {
        if (cellValues?.row?.details) {
          return moment((cellValues?.row?.details?.startSubscription).trim(), "DD-MM-YYYY").format("DD/MM/YYYY");
        } else {
          return null;
        }
      },
    },

    {
      field: "lastBalance",
      headerName: "$ Dernier Rééq",
      width: 150,
      headerClassName: "dark:text-slate-100",
      headerAlign: "center",
      type: "number",
      valueGetter: (params) => {
        if (params.row.lastBalance) {
          return params.row.lastBalance;
        } else {
          return 0;
        }
      },
      renderCell: (cellValues) => {
        if (cellValues.row.lastBalance) {
          return cellValues.row.lastBalance;
        } else {
          return "-";
        }
      },
    },

    {
      field: "action",
      headerName: "Vue Client",
      sortable: false,
      filterable: false,
      disableExport: true,
      headerClassName: "dark:text-slate-100",

      renderCell: (cellValues) => {
        return (
          <Controls.Button
            xsFont
            small
            variant="none"
            color={"text-primary dark:text-slate-100 hover:text-secondary"}
            text={"Voir Détails"}
            onClick={(event) => {
              //handleClick(event, cellValues);
              navigate(`/rebalancing/${cellValues.row.id}`);
              //console.log(cellValues.row.id)
            }}
          />
        );
      },
    },
    {
      field: "to",
      headerName: "Action",
      sortable: false,
      filterable: false,
      disableExport: true,
      headerClassName: "dark:text-slate-100",

      renderCell: (cellValues) => {
        return (
          <Controls.Button
            xsFont
            small
            variant="none"
            color={"text-primary dark:text-slate-100 hover:text-secondary"}
            text={t("button.edit")}
            onClick={(event) => {
              //handleClick(event, cellValues);
              navigate(`/support/user/${cellValues.row.id}`);
              //console.log(cellValues.row.id)
            }}
          />
        );
      },
    },
    {
      field: "reeq",
      headerName: "Rééquilibrage",
      sortable: false,
      filterable: false,
      disableExport: true,
      headerClassName: "dark:text-slate-100",

      renderCell: (cellValues) => {
        return (
          <>
            {reeqProcessing && reeqProcessing ? (
              <Controls.Button
                xsFont
                small
                disabled={true}
                variant="none"
                color={"text-white bg-indigo-400 hover:bg-indigo-600  dark:text-slate-100 "}
                text={t("Rééquilibrer")}
              /* onClick={async (event) => {
               try {
                 toast.info("En cours");
                 //const result = await axiosPrivate.get(`/api/rebalancing/runReeq/${cellValues.row.id}`);
                 //console.log("Result", result);
                 if (result.data === "Rebalancing done") {
                   toast.success("Rééquilibrage fait");
                 }
               } catch (error) {
                 console.log(error);
                 toast.error("Erreur");
               }

             }}*/
              />
            ) : (
              <Controls.Button
                xsFont
                small
                disabled={cellValues.row.id == 1 || cellValues.row.id == 82 || cellValues.row.id == 384 ? false : true}
                variant="none"
                color={"text-white bg-indigo-400 hover:bg-indigo-600  dark:text-slate-100 "}
                text={t("Rééquilibrer")}
                onClick={async (event) => {
                  try {
                    toast.info("En cours ");
                    setReeqProcessing(true);
                    const result = await axiosPrivate.get(`/api/rebalancing/runReeq/${cellValues.row.id}`);
                    //console.log("Result", result);
                    if (result.data === "Rebalancing done") {
                      toast.success("Rééquilibrage fait");
                      setReeqProcessing(false);
                    }
                  } catch (error) {
                    console.log(error);
                    toast.error("Erreur");
                  }
                }}
              />
            )}
          </>
        );
      },
    },
    {
      field: "Stop",
      headerName: "",
      sortable: false,
      filterable: false,
      disableExport: true,
      headerClassName: "dark:text-slate-100",
      width: 200,

      renderCell: (cellValues) => {


        if (secondsLeft > 0 && cellValues.row.id == selectedID) {
          return (
            <Controls.Button
              xsFont
              small
              variant="none"
              color={"text-white bg-green-500 dark:text-slate-100 hover:bg-red-600"}
              text={`Confirmer (${secondsLeft})`}
              onClick={(event) => {
                handleStop(cellValues.row.id)
                start(15)
                setSelectedId(0)
                //setStopOpen(true)
                //handleClick(event, cellValues);
                // navigate(`/support/user/${cellValues.row.id}`);
                //console.log(cellValues.row.id)
              }}
            />
          )
        }
        return (
          <Controls.Button
            xsFont
            small
            variant="none"
            color={"text-white bg-red-500 dark:text-slate-100 hover:bg-red-600"}
            text={'Stop'}
            onClick={(event) => {
              start(15)
              setSelectedId(cellValues.row.id)
              //handleClick(event, cellValues);
              // navigate(`/support/user/${cellValues.row.id}`);
              //console.log(cellValues.row.id)
            }}
          />
        );
      },
    },
  ];

  function getDatesInRange2(startDate, endDate) {
    let date = moment(startDate, "DD-MM-YYYY");
    const dates = [];
    while (date <= moment(endDate, "DD-MM-YYYY")) {
      dates.push(moment(date).format("DD-MM-YYYY"));
      console.log(dates);
      date = moment(date, "DD-MM-YYYY").add(1, "days");
    }
    return dates;
  }

  const checkRange = (value) => {
    if (value[0] != null && value[1] != null) {
      console.log("startDate", value[0], "endDate", value[1]);
      dispacth(addFilter(value));
      console.log(getDatesInRange2(value[0], value[1]));
      setIsFiltered(true);
      //dispacth(filterUsersByDate(value))
      /*setRangeFilter({
                startDate: value[0],
                endDate: value[1]
            })*/
    }
  };

  const dateFormat = "DD-MM-YYYY";
  const NewToolbar = () => {
    return (
      <div className="flex flex-col md:flex-row p-2">
        <GridToolbar />
        {/* <div className="flex space-x-2 items-center">
                    <FilterIcon className=' flex-none w-4 h-4 text-blue-500' />
                    {/* date filter
                    <Controls.RangePicker
                        onCalendarChange={(value) => checkRange(value)}
                        //value={filters ? filters : []}
                        defaultStart={filters && filters[0] || ''}
                        defaultEnd={filters && filters[1] || ''}
                    />
                </div> */}
      </div>
    );
  };

  /*
        useEffect(() => {

            var startDate = new Date(rangeFilter?.startDate);
            var endDate = new Date(rangeFilter?.endDate);
            console.log("startDate", startDate, "endDate", endDate)
            setState(getDatesInRange(startDate, endDate))
            /*const avengers = newUser?.filter(u => datess.includes(moment(u.createdAt).format("DD-MM-YYYY")))
            setUsersFilter(avengers)

        }, [rangeFilter])*/
  //console.log(userData.all)

  return (
    <>
      {/* <Controls.Button text="Choose date" onClick={() => setIsOpen(!isOpen)} /> */}
      {/* <Controls.RangePicker onCalendarChange={(value) => console.log(value)} /> */}
      {/* <DialogBox isOpen={isOpen} setIsOpen={setIsOpen}>
                <DateRange
                    locale={fr}
                    localeText={fr}
                    onChange={item => setState([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={state}
                    direction="horizontal"
                />
            </DialogBox> */}

      {/* steps:
details:
addressBinance:
memo: {complete: true, comment: 'Ok'}
[[Prototype]]: Object
apis:
api: {complete: false, comment: 'APIs manquant'}
[[Prototype]]: Object
capital: {initial: {…}, recurring: {…}}
documents: {sourceOfFund: {…}, link: {…}}
[[Prototype]]: Object */}

      <DialogBox isOpen={isOpen} setIsOpen={setIsOpen}>
        <div>
          'Etes-vous sur de vouloir stopper le client, cet action est irreversible'
        </div>

      </DialogBox>

      <DialogBox isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="flex flex-col gap-y-2">
          <div className="text-sm text-slate-900 font-bold">{emailSelected && emailSelected}</div>
          <div>
            <div className="font-semibold text-md">Documents</div>
            {/* <div>Source de Renvue {dataSteps && dataSteps?.documents?.sourceOfFund ? (<CheckCircleIcon className='w-4 h-4 text-green-500' />) : (<XCircleIcon />)} </div> */}
            <div className="shadow-lg p-2">
              <div className="flex items-center text-sm text-slate-800">Source de Revenue {<MyIndicator value={dataSteps && dataSteps?.documents?.sourceOfFund.complete} />} </div>
              <div className="flex items-center text-xs text-slate-500">{dataSteps && dataSteps?.documents?.sourceOfFund.comment} </div>
            </div>
            <div className="shadow-lg p-2">
              <div className="flex items-center text-sm text-slate-800">Google Link {<MyIndicator value={dataSteps && dataSteps?.documents?.link.complete} />}</div>
              <div className="flex items-center text-xs text-slate-500">{dataSteps && dataSteps?.documents?.link.comment} </div>
            </div>
          </div>
          <div className="font-semibold text-md flex items-center">APIS {<MyIndicator value={dataSteps && dataSteps?.apis?.api.complete} />}</div>
          <div className="font-semibold text-md flex items-center">Binance Memo {<MyIndicator value={dataSteps && dataSteps?.addressBinance?.memo.complete} />}</div>

          <div>
            <div className="font-semibold text-md">Capital</div>
            <div className="shadow-lg p-2">
              <div className="flex items-center text-sm text-slate-800">Capital Initial {<MyIndicator value={dataSteps && dataSteps?.capital?.initial.complete} />} </div>
              <div className="flex items-center text-xs text-slate-500">{dataSteps && dataSteps?.capital?.initial.comment} </div>
            </div>
            <div className="shadow-lg p-2">
              <div className="flex items-center text-sm text-slate-800">Capital Reccurent {<MyIndicator value={dataSteps && dataSteps?.capital?.recurring.complete} />}</div>
              <div className="flex items-center text-xs text-slate-500">{dataSteps && dataSteps?.capital?.recurring.comment} </div>
            </div>
          </div>
        </div>
      </DialogBox>

      {users ? (
        <DataGrid
          components={{
            Toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          // componentsProps={{
          //     footer: { total }
          // }}
          //autoPageSize={true}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          initialState={{
            sorting: {
              sortModel: [{ field: "id", sort: "desc" }],
            },
          }}
          rows={isFiltered ? listUser.filter((u) => filters.includes(moment(u.createdAt).format("DD-MM-YYYY"))) : listUser}
          columns={auth.role.includes("3") ? column : auth.role.includes("0") ? columnSuperAdmin : columnAdmin}
          //pagination={true}
          onCellClick={(value) => console.log("yes", value.id)}
          disableColumnSelector
          disableDensitySelector
          getRowClassName={(params) => `text-slate-800 dark:text-slate-100`}
          density="compact"
          className="text-red-500"
          autoPageSize

        /* onStateChange={(state) => {
             const visibleRows = state.filter.visibleRowsLookup;
             let visibleItems = [];
             for (const [id, value] of Object.entries(visibleRows)) {
                 if (value === true) {
                     visibleItems.push(Number(id));
                 }
             }
             const res = listUser?.filter((item) => visibleItems.includes(item.id));
             const total = res
                 .map((item) => item.id)
                 .reduce((a, b) => a + b, 0);

             setTotal(total);
         }}*/
        />
      ) : (
        <Loading />
      )}
    </>
  );
}

export default Users;
