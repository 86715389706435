import React, { useState, useEffect } from "react";

function BannerInfo(props) {
  const { text, type } = props;
  const [color, setColor] = useState('')

  useEffect(() => {

    const getColor = () => {
      switch (true) {
        case type == "success":
          setColor('bg-green-500')
          break
        case type == "info":
          setColor('bg-sky-500')
          break;
        case type === "warning":
          setColor('bg-yellow-500')
          break;
        case type === "danger":
          setColor('bg-red-500')
          break;
        default:
          setColor('bg-slate-500')
          break;
      }
    }
    getColor()

  }, [type])


  return (
    <div
      className={` my-5 p-3 
${color} rounded text-center font-semibold text-slate-100 `}
    >
      {text}
    </div>
  );
}

export default BannerInfo;
