import React, { useState, useEffect } from "react";
import Controls from "../components/controls/Controls";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Loading from "../components/Loading";
import MyLine from "../components/chart/MyLine";
import { useParams } from "react-router-dom";
import { authSelector } from "../features/authSlice";
import { useSelector } from "react-redux";
import TopBar from "../components/TopBar";
import * as sideMenus from "../utils/sideMenus";
import Sidebar from "../components/Sidebar";
import MyLineCapital from "../components/chart/MyLineCapital";

const SimulatorScreen = (props) => {
  const { t } = props;
  const { id } = useParams();
  const auth = useSelector(authSelector);

  const [values, setValues] = useState({
    actifK: 5000,
    totalK: 1000,
    reserveK: 0,
    recurringK: 150,
  });

  const axiosPrivate = useAxiosPrivate();
  const [originaldata, setOriginaldata] = useState();
  const [dataRow, setDataRow] = useState();
  const [dataAf, setDataAf] = useState();
  const [dataCapital, setDataCapital] = useState();
  const [recalculate, setRecalculate] = useState(false);
  const [graphType, setGraphType] = useState("logarithmic");

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getAf = async (myId) => {
      try {
        const response = await axiosPrivate.get(`/api/rebalancing/findBy/af/${myId}`, {
          signal: controller.signal,
        });
        isMounted && setDataAf(response.data);

        console.log(response.data);

        isMounted && setDataCapital(response.data.filter((f) => f.capital !== null));
      } catch (error) {
        console.log("here", error);
      }
    };
    if (auth?.role.includes("2") || auth?.role.includes("4")) {
      getAf(auth?.id);
    } else {
      getAf(id);
    }

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  /* const handleSimulation = () => {
        setRecalculate(!recalculate)
    }*/

  return (
    <>
      <TopBar menus={sideMenus.menusClient(t, id, auth.role)} />
      <div className="flex flex-row flex-grow ">
        <Sidebar menus={sideMenus.menusClient(t, id, auth.role)} />

        <div className="flex flex-row flex-1 mt-2 md:ml-52 ">
          <div className="p-4 flex-1 gap-3 ">
            <div className="  dark:bg-slate-800 gap-y-5 rounded-lg  ">
              <h3 className="font-bold text-2xl text-slate-700 dark:text-slate-100">Evolution</h3>

              <div className=" w-full h-[500px] bg-slate-100 dark:bg-slate-800 p-2 rounded">
                {dataCapital ? (
                  <MyLineCapital
                    graphType={"logarithmic"}
                    values={values && values}
                    data={dataCapital && dataCapital}
                  />
                ) : (
                  <Loading />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SimulatorScreen;
