import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import moment from "moment";
import "moment/locale/fr";
import { Tab } from "@headlessui/react";
import { getUserDetails, userDataSelector, resetUserDetails } from "../../features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import InfoUser from "../userDetails/InfoForm";
import KeyUser from "../userDetails/KeyForm";
import DocumentsForm from "../userDetails/DocumentsFormSimple";
import CapitalForm from "../userDetails/CapitalForm";
import { ChevronLeftIcon, UserCircleIcon } from "@heroicons/react/solid";
import Loading from "../Loading";
import { useNavigate, useLocation } from "react-router-dom";
import { authSelector } from "../../features/authSlice"
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function OneUser(props) {
  const { t } = props;
  const { id } = useParams();
  const userData = useSelector(userDataSelector);
  const auth = useSelector(authSelector);
  const [user, setUser] = useState([]);
  const [listUser, setListUser] = useState([]);
  const [parentObj, setParentObj] = useState();
  const [motifNotOk, setMotifNotOk] = useState();
  const [errorInputnotOk, setErrorInputnotOk] = useState();
  const [otherMotifNotOk, setOtherMotifNotOk] = useState();
  const [errorOther, setErrorOther] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const dispacth = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const goBack = () => navigate(-1);
  const today = moment(new Date()).format("YYYY-MM-DD")
  useEffect(() => {
    dispacth(resetUserDetails());
    let isMounted = true;
    const controller = new AbortController();

    const getListUsers = async () => {
      try {
        const response = await axiosPrivate.get(`/api/users/genericFindAll`, {
          signal: controller.signal,
        });
        isMounted && setListUser(response.data);
      } catch (error) {
        if (error.response.status != 403) {
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };

    const getUser = async () => {
      try {
        const response = await axiosPrivate.get(`/api/users/genericFindOne/${id}`, {
          signal: controller.signal,
        });
        console.log("res", response.data);
        isMounted && setUser(response.data);
        dispacth(getUserDetails(response.data));
      } catch (error) {
        if (error.response.status != 403) {
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };
    getListUsers();
    getUser();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (userData.parent) {
    }
  }, [userData]);

  useEffect(() => {
    const parentData = listUser.filter((u) => u.id == userData.parent);
    setParentObj({
      fName: parentData[0]?.firstName,
      lName: parentData[0]?.lastName,
    });
  }, [listUser, userData]);

  useEffect(() => {
    if (motifNotOk) return setErrorInputnotOk('')
  }, [motifNotOk])

  useEffect(() => {
    if (otherMotifNotOk) return setErrorOther('')
  }, [otherMotifNotOk])

  let categories = ["Profil", "Documents", "Capital", "APIs"];
  return (
    <>
      <div className="flex flex-row">
        <div className="flex flex-col w-full p-4 mt-2 ">
          <div className="flex items-center mb-2">
            <div className="flex gap-x-4">
              <ChevronLeftIcon
                className="h-6 rounded-full dark:text-slate-100 hover:bg-slate-300 cursor-pointer"
                onClick={() => goBack()}
              />
              <UserCircleIcon className="h-6 dark:text-slate-100" />
            </div>
            <div className="flex-1 font-semibold text-sm ml-1 text-slate-700 dark:text-slate-100">{userData?.lastName} {userData?.firstName} </div>
          </div>
          {userData.length != 0 ? (
            <div className="flex flex-col w-full px-2 sm:px-0 mt-2">
              <Tab.Group>
                {/* <Tab.List className="flex space-x-1 rounded bg-blue-900/20 p-1 max-w-md"> */}
                <Tab.List className="flex space-x-1 rounded bg-blue-900/20 min-w-md">
                  {categories.map((category) => (
                    <Tab
                      key={category}
                      className={({ selected }) =>
                        classNames(
                          "w-full rounded py-2 text-sm font-medium leading-5  text-primary dark:text-slate-100 focus:outline-none transition-colors ",
                          selected
                            ? "bg-white dark:bg-secondary shadow-sm  border-b-2 border-b-secondary "
                            : " hover:bg-white/[0.12] hover:text-white "
                        )
                      }
                    >
                      {category}
                    </Tab>
                  ))}
                </Tab.List>
                <Tab.Panels className="h-full shadow py-2 bg-slate-100 dark:bg-slate-900 ">
                  <Tab.Panel className={"flex flex-col min-h-full items-center "}>
                    <InfoUser data={userData && userData} t={t} uid={id} />
                  </Tab.Panel>
                  <Tab.Panel>
                    <DocumentsForm data={userData && userData?.documents} t={t} uid={id} />
                  </Tab.Panel>


                  <Tab.Panel>
                    <CapitalForm data={(userData && userData?.details) || ""} t={t} uid={id} />
                  </Tab.Panel>

                  <Tab.Panel>
                    <KeyUser data={(userData && userData?.details) || ""} t={t} uid={id} />
                  </Tab.Panel>

                </Tab.Panels>
              </Tab.Group>


            </div>
          ) : (
            <Loading />
          )}


        </div>

      </div>




    </>
  );
}

export default OneUser;
