import { createSlice } from "@reduxjs/toolkit";

const initialState = 'show'

export const sideBarSlice = createSlice({
    name: 'show',
    initialState,
    reducers: {
        updateShow: (state, action) => (action.payload)
    }
});

export const { updateShow } = sideBarSlice.actions
export const sideBarSelector = (state) => state.show;

export default sideBarSlice.reducer
