import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";
import { ChevronRight } from "@mui/icons-material";
import { useSelector } from 'react-redux';
import { authSelector } from '../features/authSlice';

function SettingScreen(props) {
  const { t } = props;
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const auth = useSelector(authSelector)
  return (
    <div className="p-4">
      <div className="flex items-center">
        <div className="hover:bg-slate-300 rounded-full w-fit p-1.5 ">
          <ArrowNarrowLeftIcon className="h-5 w-5 hover:cursor-pointer" onClick={goBack} />
        </div>
        <div className="font-semibold ml-2"> Paramètres</div>
      </div>

      {/* <div className="bg-slate-100 mx-auto mt-4 p-2 rounded max-w-md flex ">
        <div className="flex-1 cursor-pointer hover:text-sky-500" onClick={() => navigate("/secure")}>
          {t(`label.securePnl`)}
        </div>
        <ChevronRight className="h-5 w-5 cursor-pointer hover:text-sky-500" onClick={() => navigate("/secure")} />
      </div> */}
      <div className=" mx-4 p-2">
        {/* <div className="font-light"> Sécurité</div> */}

        <div className="bg-slate-100 mx-auto mt-4 p-2 rounded max-w-md flex ">
          <div className="flex-1 cursor-pointer hover:text-sky-500" onClick={() => navigate("/reauth")}>
            {/* {t(`label.resetPassword`)} */}
            Sécurité du compte
          </div>
          <ChevronRight className="h-5 w-5 cursor-pointer hover:text-sky-500" onClick={() => navigate("/reauth")} />
        </div>
        {auth?.role?.includes('2') &&
          <div className="bg-slate-100 mx-auto mt-4 p-2 rounded max-w-md flex ">
            <div className="flex-1 cursor-pointer hover:text-sky-500" onClick={() => navigate("/keys")}>
              {/* {t(`label.resetPassword`)} */}
              Réglages API
            </div>
            <ChevronRight className="h-5 w-5 cursor-pointer hover:text-sky-500" onClick={() => navigate("/keys")} />
          </div>
        }

      </div>
    </div>
  );
}

export default SettingScreen;
