import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "../features/authSlice";
import userReducer from "../features/user/userSlice";
import counterReducer from "../features/counter/counterSlice";
import filterReducer from "../features/filterSlice";
import listUserReducer from "../features/listUserSlice";
import themeReducer from "../features/themeSlice";
import sideBarReducer from "../features/sideBarSlice";
import monitoringReducer from "../features/monitoringSlice";
import zapSliceReducer from "../features/zapSlice";
import ticketReducer from "../features/ticketSlice";
import followReducer from "../features/followSlice"
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "theme"],
};

const roodReducer = combineReducers({
  auth: authReducer,
  listUser: listUserReducer,
  user: userReducer,
  counter: counterReducer,
  filter: filterReducer,
  theme: themeReducer,
  show: sideBarReducer,
  monitoring: monitoringReducer,
  zap: zapSliceReducer,
  tickets: ticketReducer,
  follow: followReducer
});

export default persistReducer(persistConfig, roodReducer);
