import { createSlice, current } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = []

export const listUserSlice = createSlice({
    name: 'listUser',
    initialState,
    reducers: {
        setallUsers: (state, action) => {
            return action.payload;
        },
        removeUser: (state, action) => {
            const newState = state.filter(f => f.id != action.payload)

            return newState
        },
        updateUsersList: (state, action) => {
            const changedIdx = state.findIndex(u => (u.id == action.payload.uid))
            //const newArray = [ current(state)]
            return [
                ...state.slice(0, changedIdx),
                {
                    ...state[changedIdx],
                    date_followup: action.payload?.date_followup || '',
                    comment_followup: action.payload?.comment_followup || ''
                },
                ...state.slice(changedIdx + 1)]
            // newArray[changedIdx].date_followup = action.payload?.date_followup || ''

            //newArray[changedIdx].comment_followup = action.payload?.comment_followup || ''
            //console.log("dastsdas", typeof newArray)
            //return newArray
            //state.fiter(u => (u.id == 198))
            //console.log("dastsdas", dataToUpdate)
        }
    }
});

export const { setallUsers, filterUsersByDate, updateUsersList, removeUser } = listUserSlice.actions
export const listUserSelector = (state) => state.listUser;
export default listUserSlice.reducer
